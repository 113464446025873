import React from 'react'
import CommentSection from '../Snapshot/CommentsSection';
import LinkedEvidences from './LinkedEvidences';
import TransactionDetails from './TrandactionDetails';
import UnlinkedEvidences from './UnlinkedEvidences';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import MasterService from '../../Services/MasterService';
import { Link, withRouter } from 'react-router-dom';
import RouteLeavingGuard from '../../Utils/RouteLeavingGuard';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { changeDateFormate, changeDateTimeFormat } from '../../Helpers/DateFormat';
import {
	setBusinessNarration, setEvidenceFilterData, setEvidenceListData, setIsPersonal,
	setLinkedEvidenceList, setNoSupportingDocument, setTempLinkedEvidenceList, setTempVoucherList,
	setTransactionDetails, setTransactionStatus, setVoucherList, setSuggestedEvidenceListData,
	setSuggestedEvidenceIds, setCostCategoryList, setEntryStatus, setAccountStatus
} from '../../Redux/Actions/MoneyLink';
import { setSnapshotFilterData, setSnapshotCardFilter, setPageNo } from '../../Redux/Actions/Snapshot';
import ConfirmPopup from '../../Components/BoilerPlate/ConfirmPopup';
import StorageData from "../../Helpers/StorageData";
import ViewEvidenceReport from '../../Components/BoilerPlate/ViewEvidenceReport';
import LinkHistoryView from '../../Components/BoilerPlate/LinkHistoryView';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import _, { lte } from "lodash";
import ChangeLog from '../../Components/BoilerPlate/ChangeLog';
import HelpModal from '../../Components/BoilerPlate/HelpModal';
import { createLog, formatLogData, sendLog } from '../../Helpers/CreateLogs';
import { LEDGER_ENTRIES_CODE, LINKING_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../Helpers/Constants';
import { Multiselect } from "multiselect-react-dropdown"
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { OverlayTrigger, Tooltip, Badge, Button } from 'react-bootstrap';
import { confirm } from '../../Components/BoilerPlate/Confirm';
import { areGuidsUnique, showAliasAcctNo } from '../../Helpers/HelperFunction';

// Intro js user guid
import { render } from "react-dom";
import { Steps } from "intro.js-react";
// End Intro js user guid 
import Select from '@mui/material/Select';
import { decodeHtml } from '../../Helpers/HelperFunction';
import Mousetrap from 'mousetrap';

class LinkedTransactionsForm extends React.Component {
	constructor(props) {
		super(props);

		this.multiselectRef = React.createRef();
		this.props.dispatch(setAppilcationLoder(true));
		this.state = {
			show_help: false,
			show_link_history: false,
			link_history_evidence: {},
			link_history_money_link: [],
			view_evidence: false,
			report_url: "",
			is_dirty: false,
			toggleNoDocumentConfirm: false,
			saveData: false,
			ledgerNameList: {
				ledger_list: [],
				last_updated_at: null
			},
			transaction_id: StorageData.getTransactionId() || this.props.match.params.transaction_id,
			evidence_tab: 1,
			evidence_list: [],
			per_page_items: 10,
			page_no: 1,
			filter_by: 0,
			search: "",
			suggested_evidence_list: [],
			evidences: [],
			vouchers: [],
			mode: 0,
			voucher_type: 1,
			total_credit_amount: 10,
			total_debit_amount: 10,
			voucher_id: 1,
			ledgers: [],
			remove_ledgers: [],
			voucher_list_from_db: [],
			is_valid_ledger: true,
			is_valid_voucher: true,
			save_and_next: true,
			old_data: {},
			logs_list: {},
			save_and_redirect: false,
			next_route: false,
			closed: false,
			read_only: false,
			status_reverted: false,
			process_no_ledgers: false,
			show_filter: true,
			communication_bar_tasks_dirty: false,
			communication_bar_notes_dirty: false,
			communication_bar_msgs_dirty: false,
			communication_bar_private_msgs_dirty: false,
			submit_click: 0,
			selectedOption: {},
			options: [
				{ name: "Not Ready", value: "2", disabled: !this.props.features_permissions_list.includes(LINKING_CODE) },
				{ name: "Ready", value: "1", disabled: !this.props.features_permissions_list.includes(LINKING_CODE) },
				{ name: "Not Processed", value: "6", disabled: !this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) },
				{
					name: "Processed", value: "3", disabled: !this.props.features_permissions_list.includes(LINKING_CODE) &&
						!this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) ? true : false
				},
				{ name: "Synced", value: "5", disabled: true },
			],
			task_manager_task_id: null,

			// Intro js user guid
			// this.state = {
			stepsEnabled: false,
			initialStep: 0,
			vaild_voucher : false,

			steps: [
				{
					element: ".transdetail_moneyin",
					intro: "After looking at the transaction, you can decide if there will be a evidence or its a personal expense.",
					// position: 'right',
					// hideNext: true 
				},
				{
					element: ".evidences_moneyin",
					intro: "We will recommend you the evidence with a level of confidence indicated below the card. You can view the evidence and verify the same. You can drag the same to ‘Link Evidence panel’. If you don't find the right one, you can also use the search function. You can also add a new Snapshot by clicking on the “add evidence’ card. You can link multiple pieces of evidence to a transaction and view them here.",
					position: 'left'
				},
				// {
				// 	element: ".evi_cards_moneyin",
				// 	intro: "You can link multiple pieces of evidence to a transaction and view them here."
				// },
				// {
				// 	element: ".buss_nar_moneyin",
				// 	intro: "Business team can give their description about the particular Transaction in this section.",
				// },
				{
					element: ".ledger_moneyin",
					intro: "Ledger section allows the accountant to post the entries. The same gets exported to your accounting software.",
					position: 'left'
				},
				// {
				// 	element: ".linked_evidences_moneyin",
				// 	intro: "You can link multiple pieces of evidence to a transaction and view them here.",
				// },
				{
					element: ".commbar_moneyin",
					intro: "Communication bar runs across different modules on the platform. You can have a Private Chat (within your organization) or Public chat (across all the users). You can also use this section to add notes or assign tasks.",
				},
				{
					element: ".redyDraft_moneyin",
					intro: "While saving the transaction, you can decide the status for the same. **Draft :- It's like a draft mode where you have not added all the details. **Ready :- This indicates that the Accountant can now pick this up for Processing.",
					position: 'left'
				},
				{
					element: ".status_moneyin",
					intro: "**Accounted :- When accountant makes the ledger entries, he can mark it as accounted and these will be picked up for exporting to accounting software. **Pending :- Junior accountant can make the ledger entries and keep it in this status for senior’s approval. **Synced :- Once the entries are exported to accounting software, they get automatically marked as synced.",
					position: 'left'
				},
				{
					element: ".save_moneyin",
					intro: "You can save the page by clicking here. Document should always be in Draft state for editing it.",
					position: 'left'
				},
				{
					element: ".filler_moneyin",
					intro: "You can use this filter to change your focus area based on different parameters.",
				},
			],
			// };
			// End Intro js user guid
			task_manager_task_id: null,
			remove_cost_category: [],
			remove_cost_center: [],
			remove_billwise_allocation: [],
			synced_by_user: '',
			// system_suggested_status:'',
			old_data_log: {},
			billwise_allocation: {},
			backup_evidences: [],
			ledger_recommendation_loading: false,
			evidence_list_loading: false,
			transaction_detail: [],
			auto_ready:false,
			auto_accounted :false,
			auto_ready_accounted:false,
			voucherTypeList :[],
		}
		this.VIEW_LINKED_ENTRIES_CODE = this.props.features_permissions_list.includes(VIEW_LINKED_ENTRIES_CODE)
		if ((this.props.features_permissions_list.includes(LINKING_CODE) || this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE))) {
			this.VIEW_LINKED_ENTRIES_CODE = false;
		}

		this.props.dispatch(setAppilcationLoder(true));
	}

	selectedOption = (arr, id) => {
		if (id !== 0) {
			let selected = arr.find((item) => item.value == id)
			this.setState({ selectedOption: { ...selected } })
		}
	}

	viewEvidence = (url) => {
		this.setState({
			view_evidence: true,
			report_url: url
		})
	}
	showLinkHistory = (module, id) => {
		let detail = {
			evidence_id: id,
			module: module,
			transaction_id: this.state.transaction_id
		}
		MoneyLinkService.getLinkHistory(detail).then((data) => {
			if (data.response_code === 200) {
			
				this.setState({ link_history_evidence: data.result.evidence });
				this.setState({ link_history_money_link: [] }, () => {
					this.setState({ link_history_money_link: data.result.money_link }, () => {
						//console.log("this.state.link_history_money_link--", this.state.link_history_money_link)
					})
				})
				this.setState({ show_link_history: true })
			} else {
				toast.error(data.message)
			}
		})
	}
	toggleLinkHistoryPopup = () => {
		this.setState({ show_link_history: false, view_evidence: false, report_url: '' })
	}
	setPageNo = (page_no) => {
		this.setState({ page_no: page_no }, () => this.getEvidenceList())
	}
	changeEvidenceTab = (tab) => {
		this.setState({ evidence_tab: tab })
	}

	searchFilter = (value) => {
		this.setState({
			search: value, page_no: 1
		}, () => this.getEvidenceList(1))
	}


	applyFilter = (type) => {
		if (type == "reset") {
			this.props.dispatch(setEvidenceListData({ "evidences": [] }));
			this.setState({
				page_no: 0
			})
		} else {
			this.setState({
				page_no: 1
			}, () => {
				//type == "apply" ? this.getEvidenceList(2) : this.getEvidenceList(2);
				type == "apply" ? this.getEvidenceList(2) : null;
			})
		}
	}

	getTempEvidenceList = () => {
		return this.props.temp_linked_evidences.map((evidence, index) => {
			return {
				id: evidence.id || evidence.mod_id,
				module: evidence.module
			}
		})
	}

	getEvidenceList = (filter_by = this.state.filter_by) => {
		this.setState({
			filter_by: filter_by,
			evidence_list_loading: true
			// evidence_list: []
		})
		//console.log('in api  ', this.props.module)
		let filter_data = {
			"per_page_items": this.state.per_page_items,
			"page_no": this.state.page_no,
			"filter": filter_by,
			"search": this.state.search,
			// "type": parseInt(this.props.transaction_details.type),
			"exclude_evidences": this.getTempEvidenceList(),
			"filter_box": {
				"vendor": !!this.props.vendor ? this.props.vendor : null,
				"from_amount": !!this.props.from_amount ? this.props.from_amount : null,
				"to_amount": !!this.props.to_amount ? this.props.to_amount : null,
				"from_date": !!this.props.from_date ? changeDateFormate(this.props.from_date) : null,
				"to_date": !!this.props.to_date ? changeDateFormate(this.props.to_date) : null,
				"status": !!this.props.status ? parseInt(this.props.status) : null,
				"module": !!this.props.module && this.props.module.length !== 0 ? [parseInt(this.props.module)] : [1],
			},
		};
		MoneyLinkService.getEvidenceList(filter_data).then(
			(data) => {
				if (data.response_code == 200) {
					Object.entries(data.result).map(item => {
						let name = item[0]
						if (item[0] === "page_no")
							name = 'evidence_page_no'
						let list_data = {
							[name]: item[1]
						}
						//console.log(list_data.evidences)
						if (list_data.evidences != undefined) {
							this.setState({ backup_evidences: list_data.evidences })
						}
						this.props.dispatch(setEvidenceListData(list_data));
					})
				} else {
					toast.error(data.message);
				}
				this.setState({ evidence_list_loading: false })
			});
	}

	// getVoucherListForRemoveCase = () => {
	// 	this.setState({ voucher_list_from_db: [] })
	// 	MoneyLinkService.getVoucherList(this.state.transaction_id).then(data => {
	// 		if (data.response_code == 200) {
	// 			console.log(data.result)
	// 			this.setState({ voucher_list_from_db: data.result })
	// 		}
	// 	})
	// }

	redirectToTaskManager = () => {
		this.props.history.push('/task-manager/create/' + this.state.task_manager_task_id)
	}

	componentDidMount() {
		// this.getLedgerNameList();
		//console.log('mount')
		this.getLedgerMaster();
		this.getVoucherTypeList();
		this.setState({ task_manager_task_id: StorageData.getTaskManagerId(), transaction_id: this.props.match.params.transaction_id })
		//this.newOptionsArray(this.state.options)
		this.clearEvidenceListData()
		let current_details = {
			"transaction_id": this.props.match.params.transaction_id,
			"evidences": this.handleLinkEvidence("first_time"),
			"vouchers": {},
			"business_narration": this.props.business_narration,
			"is_personal": this.props.is_personal,
			"no_supporting_document": this.props.no_supporting_document,
			"status": this.props.transaction_status,
		}

		this.setState({ old_data: current_details })

		// shortcut key logic
		Mousetrap.bind('ctrl+alt+s',this.preSaveMoneyLink)

		setTimeout(() => {
			this.getTransactionDetails()
		}, 2000);
		this.clearEvidenceListData()

	}

	getLedgerMaster = () => {
		MoneyLinkService.getLedgerMaster().then(data => {
			if (data.response_code == 200) {

				let ledgersData = {};
				data.result.ledger_list = data.result.ledgers;
				data.result.ledger_updated = data.result.ledger_updated;
				//this.setState({ ledgerNameList: ledgersData })
				this.setState({ ledgerNameList: data.result })
				let cost_categories_list = data.result.cost_categories.map((category) => {
					category.label = decodeHtml(category.name);
					category.value = category.guid;
					return category;
				})
				this.props.dispatch(setCostCategoryList(cost_categories_list))

			} else {
				toast.error(data.message);
			}
		})
	}

	getVoucherTypeList = async () =>{
		await MoneyLinkService.getVoucherTypeList().then(data => {
			if(data.response_code == 200){
				this.setState({voucherTypeList:data.result.voucher_types})
			}
		})
	}

	clearEvidenceListData = () => {
		this.props.dispatch(setEvidenceListData({ evidences: [] }));
		this.props.dispatch(setEvidenceListData({ has_next: false }));
		this.props.dispatch(setEvidenceListData({ has_previous: false }));
		// this.props.dispatch(setEvidenceListData({ page_no: 1 }));
		this.props.dispatch(setEvidenceListData({ total_count: 0 }));
		this.props.dispatch(setEvidenceListData({ total_pages: 0 }));
	}

	componentWillUnmount() {
		//StorageData.deleteTransactionId()

		if (this.state.saveData) {
			// toast.info('Evidence linking data is temporarily saved')
		} else {
			StorageData.deleteTransactionId()
			StorageData.deleteTaskManagerId()
			this.clearEvidenceListData()
			this.props.dispatch(setTempVoucherList([]))
		}

		Mousetrap.unbind('ctrl+alt+s')

		this.props.resetSaveAndNext()
	}

	toggleSaveData = (module) => {
		StorageData.setTransactionId(this.props.match.params.transaction_id);
		this.setState({ saveData: true }, () => {
			switch (module) {
				case '1':
					this.props.history.push('/snapshot/evidence/create')
				case '2':
					return "Cheque"
				case '3':
					return "Invoice"
			}
		})
	}

	editEvidence = (module, id) => {
		if (this.props.features_permissions_list.includes(LINKING_CODE)) {
			this.setState({ saveData: true }, () => {
				switch (module) {
					case 1:
						// SOC :: filter out particular edited snapshot id from snapshot list - BU1-I125
						StorageData.setDefaultFinacialYear(5);
						this.props.dispatch(setSnapshotFilterData({ filter_by: 2 }));
						this.props.dispatch(setPageNo(1));
						this.props.dispatch(setSnapshotCardFilter(0));

						let filter_data = {
							"type": null,
							"snapshot_number": JSON.stringify(id),
							"supplier_name": null,
							"from_amount": null,
							"to_amount": null,
							"payment_status": null,
							"created_by": null,
							"is_star": null,
							"voucher_status": null,
							"tags": "",
							"category": null,
							"payment_method": null,
							"source": null,
							"attachment_status": null,
							"gst_status": null,
							"messages": null,
							"tds_status": null,
							"is_entity_mismatch": null
						}

						this.props.dispatch(setSnapshotFilterData(filter_data));

						// EOC :: filter out particular edited snapshot id from snapshot list - BU1-I125

						setTimeout(() => {
							this.props.history.push('/snapshot/evidence/create/' + id)
						}, 500);

					case 2:
						return "Cheque"
					case 3:
						return "Invoice"
				}
			})
		}

	}

	setEvidenceListData = () => {
		let filter_data = {
			from_date: '',
			to_date: '',
			from_amount: '',
			to_amount: '',
			module: '',
			vendor: '',
			status: '',
			evidences: []
		}
		this.props.dispatch(setEvidenceFilterData(filter_data));
		this.props.dispatch(setEvidenceListData([]));
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.match.params.transaction_id !== this.props.match.params.transaction_id)) {
			this.setState({ voucher_list_from_db: [] })
			if (nextProps.match.params.transaction_id) {
				//StorageData.setTransactionId(nextProps.match.params.transaction_id)
				this.setEvidenceListData()
				this.props.dispatch(setLinkedEvidenceList([]));
				this.props.dispatch(setTempVoucherList([]))
				this.props.dispatch(setTransactionDetails({}));
				this.props.dispatch(setNoSupportingDocument(0))
				this.props.dispatch(setIsPersonal(0))
				this.props.dispatch(setTransactionStatus(0))
				this.props.dispatch(setEntryStatus(0))
				this.props.dispatch(setAccountStatus(0))
				this.props.dispatch(setBusinessNarration(''))


				this.clearEvidenceListData()
				this.changeEvidenceTab(1)
				this.setState({
					transaction_id: nextProps.match.params.transaction_id,
					is_valid_ledger: true,
					is_valid_voucher: true,
					is_dirty: false,
					show_filter: true
				}, () => {
					this.getTransactionDetails()
				})


				StorageData.deleteTaskManagerId()
				this.setState({ task_manager_task_id: null })
			}
		}
		if (nextProps.temp_linked_evidences !== this.props.temp_linked_evidences) {
			console.log("received props")
			// this.checkReadyStatus(nextProps.temp_linked_evidences, nextProps.is_personal, nextProps.no_supporting_document)
		}
		if (nextProps.is_personal !== this.props.is_personal) {
			// this.checkReadyStatus(nextProps.temp_linked_evidences, nextProps.is_personal, nextProps.no_supporting_document)
		}
		if (nextProps.no_supporting_document !== this.props.no_supporting_document) {
			// this.checkReadyStatus(nextProps.temp_linked_evidences, nextProps.is_personal, nextProps.no_supporting_document)
		}	
	}

	getTransactionDetails = async () => {
		//alert(this.state.transaction_id)
		this.props.dispatch(setAppilcationLoder(true));
		this.props.dispatch(setTransactionStatus(0))
		this.setState({ voucher_list_from_db: [], remove_billwise_allocation: [] })
		MoneyLinkService.getTransactionDetail(this.state.transaction_id).then(async (data) => {
			//this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code == 200) {
				let res = _.cloneDeep(data.result);
				//console.log(data.result.is_credit_card)
				res.financial_institute =  res.financial_institute + " " + (showAliasAcctNo(res.is_credit_card, res.account_number))
				this.props.updateTransactionDetail(res)
				this.props.dispatch(setNoSupportingDocument(data.result.no_supporting_document || 0))
				this.props.dispatch(setIsPersonal(data.result.is_personal || 0))
				this.props.dispatch(setTransactionStatus(parseInt(data.result.status)))
				this.props.dispatch(setEntryStatus(parseInt(data.result.entry_status)))
				this.props.dispatch(setAccountStatus(parseInt(data.result.accounting_status)))
				this.props.dispatch(setBusinessNarration(data.result.business_narration || ''))
				this.props.dispatch(setTransactionDetails(data.result))

				if (data.result.evidence_removed == 1) {
					toast.warning('Linked evidence removed');
				}


				// store old data for log
				const oldData = { ...this.state.old_data };
				oldData.business_narration = data.result.business_narration || '';
				oldData.no_supporting_document = data.result.no_supporting_document;
				oldData.is_personal = data.result.is_personal;
				oldData.status = data.result.status;

				oldData.account_status = data.result.accounting_status;
				oldData.entry_status = data.result.entry_status;

				this.selectedOption(this.state.options, (data.result.status))

				this.setState({
					// is_dirty: false,
				}, () => {
					this.toggleNoSupportingDocumentView()
				})

				//START - code from old getVoucherListForRemoveCase function
				// this.setState({ voucher_list_from_db: data.result })
				this.setState({ voucher_list_from_db: data.result.vouchers, transaction_detail: data.result, old_data: oldData, synced_by_user: data.result.synced_by_user })

				//allow user with ledger entries rights to edit form anytime & dissble the same user with no ledger rights
				if (this.props.features_permissions_list.includes(LINKING_CODE) && this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE)) {

					this.setState({ read_only: false })

				} else {

					if (data.result.entry_status == 2) {
						this.setState({ read_only: true })
					} else {
						//  (pending && draft OR draft ) editable - for non right user (status + form)
						if ((data.result.accounting_status == 3 && data.result.entry_status == 1) || data.result.entry_status == 1) {
							this.setState({ read_only: false })
						}
						// if accounting status :account/sync & entry status: ready  =  dissbale form + status for user with no right- 
						else if ((data.result.accounting_status == 4 || data.result.accounting_status == 5) && data.result.entry_status == 2) {
							this.setState({ read_only: true })
						}
					}
				}

				this.props.updateListStatus(data.result.id, data.result.entry_status, data.result.accounting_status, data.result.is_personal, data.result.evidences.length)

				//START - getLinkedEvidenceList
				this.props.dispatch(setLinkedEvidenceList(data.result.evidences));
				this.props.dispatch(setTempLinkedEvidenceList(data.result.evidences));
				// this.checkReadyStatus(data.result.evidences)

				// store old data for log
				const old_data = { ...this.state.old_data };
				old_data.evidences = data.result.evidences;
				this.setState({ old_data: old_data })
				// store old data for log
				//END - getLinkedEvidenceList

				//START - getSuggestedEvidenceList
				let exclude_evidences = this.getTempEvidenceList();
				let suggested_evidence_ids = []
				let result = data.result.suggested_evidences.filter(function (item) {
					suggested_evidence_ids.push(item.id)
					return exclude_evidences.filter(function (item2) {
						return item.id == item2.id;
					}).length == 0;
				});

				this.props.dispatch(setSuggestedEvidenceIds(suggested_evidence_ids));
				this.props.dispatch(setSuggestedEvidenceListData(result));
				//END - getSuggestedEvidenceList


				//START - getVoucherList
				this.props.dispatch(setTempVoucherList([]))

				let remove_cost_category = [];
				let remove_cost_center = [];
				let remove_billwise_allocation = [];
				//console.log(data.result.vouchers)
				let temp_voucher_list = _.cloneDeep(data.result.vouchers);
				//console.log(temp_voucher_list)
				temp_voucher_list = temp_voucher_list.map((voucher, index) => {
					voucher.ledgers = voucher.ledgers.map((ledger, ledger_index) => {
						let empty_cost_category = [{
							"category_guid": "",
							"category_id": 0,
							"category_name": "",
							"transaction_voucher_ledger_cost_category_id": 0,
							"cost_centers": [{
								"cost_center_guid": "",
								"cost_center_id": 0,
								"cost_center_name": "",
								"cost_center_amount": "",
								"transaction_voucher_ledger_cost_category_cost_centre_id": 0
							}]
						}];
						//let ledger_detail = !!this.state.ledgerNameList.ledger_list.findIndex((temp_ledger) => temp_ledger.guid == ledger.guid);

						//console.log(this.state.ledgerNameList)
						//console.log(ledger.guid)
						let ledger_detail = this.state.ledgerNameList.ledger_list.find(temp_ledger => {
							return temp_ledger.guid == ledger.guid;
						});

						//console.log(ledger_detail)
						if (ledger_detail != undefined && !ledger.cost_categories.length && ledger_detail.has_cost_centre == 1) {
							ledger.cost_categories = empty_cost_category;
						} else {
							if (ledger.cost_categories.length > 0) {
								ledger.cost_categories = ledger.cost_categories.map((category, category_index) => {
									let temp_category = {
										"category_id": category.cost_category_id,
										"category_guid": category.guid,
										"category_name": category.name,
										"transaction_voucher_ledger_cost_category_id": category.transaction_voucher_ledger_cost_category_id || 0,
										"cost_centers": category.cost_centres.map((cost_center, cc_index) => {
											let tmp_cost_center = {
												"cost_center_amount": cost_center.amount,
												"cost_center_id": cost_center.cost_centre_id,
												"cost_center_guid": cost_center.guid,
												"cost_center_name": cost_center.name,
												"transaction_voucher_ledger_cost_category_cost_centre_id": cost_center.transaction_voucher_ledger_cost_category_cost_centre_id || 0,
												"type": ledger.type
											}
											remove_cost_center.push(cost_center.transaction_voucher_ledger_cost_category_cost_centre_id)
											return tmp_cost_center;
										})
									}
									remove_cost_category.push(category.transaction_voucher_ledger_cost_category_id);
									return temp_category;
								})
							}
						}
						//console.log(ledger.billwise_allocation.length, ledger_detail.has_billwise)
						if (ledger_detail != undefined && !ledger.billwise_allocation.length && ledger_detail.has_billwise == 1) {
							ledger.billwise_allocation = []
						} else {

							if (ledger.billwise_allocation.length > 0) {
								ledger.billwise_allocation = ledger.billwise_allocation.map((allocation, allocation_index) => {

									let tag = "";

									if (allocation.type_of_ref == 1) {
										tag = "<b>" + allocation.invoice_no + "</b> (₹" + (allocation.amount) + ")";
									} else if (allocation.type_of_ref == 2) {
										tag = allocation.invoice_no + " (₹" + (allocation.amount) + ")";
									} else if (allocation.type_of_ref == 3) {
										tag = "Adv. (₹" + (allocation.amount) + ")";
									} else if (allocation.type_of_ref == 4) {
										tag = "On Acc. (₹" + (allocation.amount) + ")";
									}

									let temp_billwise_allocation = {
										"amount": allocation.amount,
										"invoice_no": allocation.invoice_no,
										"tag_type": allocation.tag_type,
										"transaction_voucher_ledger_billwise_allocation_id": allocation.transaction_voucher_ledger_billwise_allocation_id,
										"type": allocation.type,
										"type_of_ref": allocation.type_of_ref,
										"merchant_id": "",
										"tag": tag,
										"source": allocation.source,
										"source_id": allocation.source_id
									}
									remove_billwise_allocation.push(allocation.transaction_voucher_ledger_billwise_allocation_id);
									return temp_billwise_allocation;
								})
							}
						}
						//console.log(ledger)
						return ledger;
					})
					//console.log(voucher)
					return voucher;
				})
				//console.log(temp_voucher_list)
				this.props.dispatch(setTempVoucherList(temp_voucher_list))
				//console.log(remove_billwise_allocation);
				//return

				if (temp_voucher_list.length == 0 && this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) && data.result.evidences.length > 0) {
					if (this.props.ledger_recommendation_param == 1) {
						this.getMoneyLinkLedgerRecommendation();
					} else if (this.props.ledger_recommendation_param == 2) {
						this.props.dispatch(setAppilcationLoder(false));
						if (await confirm({
							confirmation: 'Would you like ledger recommendation?'
						})) {
							this.getMoneyLinkLedgerRecommendation();
						}
					}
				}

				// let old_data_log = {};
				// old_data_log.no_supporting_document = data.result.no_supporting_document;
				// old_data_log.is_personal = data.result.is_personal;
				// old_data_log.evidences = this.manipulateEvidences(data.result.evidences);
				// old_data_log.business_narration = data.result.business_narration || '';
				// old_data_log.vouchers = this.manipulateVouchersData(_.cloneDeep(temp_voucher_list));
				// old_data_log.accounting_status = data.result.accounting_status;
				// old_data_log.entry_status = data.result.entry_status;
				//this.setState({old_data_log, remove_cost_category, remove_cost_center, remove_billwise_allocation, is_dirty: false})
				this.setState({remove_cost_category, remove_cost_center, remove_billwise_allocation, is_dirty: false})
				this.setLogData(data.result, temp_voucher_list, 'old')

				this.props.dispatch(setAppilcationLoder(false));
			} else if (data.response_code == 404) {
				//toast.error(data.message)
				this.props.history.push({
					pathname: '/money-link',
					state: {
						showalert: true,
					}
				})
				this.props.dispatch(setAppilcationLoder(false));
			} else {
				toast.error(data.message);
				this.props.dispatch(setAppilcationLoder(false));
			}
		})
	}
	toggleNoSupportingDocumentView = (no_supporting_document = this.props.no_supporting_document) => {
		if (no_supporting_document)
			this.setState({ toggleNoSupportingDocument: true })
		else
			this.setState({ toggleNoSupportingDocument: false })
	}

	markAsNoSupportingDocument = () => {

		if (this.props.no_supporting_document) {
			this.props.dispatch(setNoSupportingDocument(0))
			this.setState({ toggleNoSupportingDocument: false, is_dirty: true })
		} else {
			if (this.props.temp_linked_evidences.length === 0) {
				this.setState({ is_dirty: true, show_filter: false })
				this.props.dispatch(setNoSupportingDocument(1))
				this.toggleNoSupportingDocumentView(1)
			} else {
				this.setState({ toggleNoDocumentConfirm: true, is_dirty: true, show_filter: false })
			}
			this.applyFilter("reset")
		}

	}

	handleChange = (e) => {
		let { name, value } = e.target
		this.setState({ [name]: value })
		if (this.state.is_dirty === false) {
			this.setState({ is_dirty: true })
		}

	}

	handleMultiselectReadyStatus = (selectedList, selectedItem) => {
		if (!selectedItem.disabled) {
			if (selectedItem.value == 2 || selectedItem.value == 3 || selectedItem.value == 6) {
				this.setState({ is_dirty: true })
				this.props.dispatch(setTransactionStatus(selectedItem.value))
			}
			else
				this.checkReadyStatus(this.props.temp_linked_evidences, this.props.is_personal, this.props.no_supporting_document, true)
		}
	}

	handleReadyStatus = (e) => {
		this.setState({ is_dirty: true })
		let { name, value } = e.target
		if (name === "status" && (value == 2)) {
			this.setState({ is_dirty: true })
			this.props.dispatch(setTransactionStatus(value))
		}
		else
			this.checkReadyStatus(this.props.temp_linked_evidences, this.props.is_personal, this.props.no_supporting_document, true, value)
	}


	handleEntryStatus = async (e) => {
		let { value } = e.target
		this.setState({ is_dirty: true ,auto_ready:false,auto_ready_accounted:false})
		this.props.dispatch(setEntryStatus(value))
		// this.checkReadyStatus(this.props.temp_linked_evidences, this.props.is_personal, this.props.no_supporting_document, true, value)
	}

	handleAccountStatus = async (e) => {
		let { value } = e.target
		this.setState({ is_dirty: true,auto_accounted:false })
		if (value != 3) {
			this.props.dispatch(setEntryStatus(2))
		}
		this.props.dispatch(setAccountStatus(value))
	}

	checkReadyStatus = (linked_evidences, is_personal, no_supporting_document, show_warning = false, status) => {
		

		console.log("::::::::::",linked_evidences.length ,status)

		if (linked_evidences.length !== 0) {
			if(this.props.entry_status !== 2){
				this.setState({auto_ready:true})
				this.props.dispatch(setEntryStatus(2))
				console.log("inside ready")
			}
			if(this.props.temp_voucher_list.length !== 0){
				if(this.props.account_status !== 4){
					this.setState({auto_accounted:true})
					this.props.dispatch(setAccountStatus(4))
				}
				this.setState({
					auto_ready_accounted:this.props.entry_status !== 2 ? true : false})
					this.props.dispatch(setEntryStatus(2))
			
			}
			// this.setState({ system_suggested_status: "This Ready status is suggested by system"	});
		 } 
		 //else {

		// 	this.props.dispatch(setEntryStatus(1))
		// 	// this.setState({ system_suggested_status: ""	});
		// 	if (show_warning)
		// 		toast.warning('Select "No Evidence" to save without evidence')
		// }
	}

	checkForReadyAccountedState = () =>{

		if(this.props.temp_voucher_list.length !== 0 ){
			if(this.props.account_status !== 4){
				this.setState({auto_accounted:true})
				this.props.dispatch(setAccountStatus(4))
			}
			this.setState({
				auto_ready_accounted:this.props.entry_status !== 2 ? true : false})
				this.props.dispatch(setEntryStatus(2))
			

		}
		

	}

	checkAccountedStatus = (updatedToAccounted) => {
		console.log("::::::::::",updatedToAccounted)
		const oldData = { ...this.state.old_data };
		if(updatedToAccounted ){
			if(this.props.account_status !== 4){
				this.setState({auto_accounted:true})
				this.props.dispatch(setAccountStatus(4))
			}
			console.log("when we update the accounted")
			this.setState({
				auto_ready_accounted:this.props.entry_status !== 2 ? true : false
			})
			this.props.dispatch(setEntryStatus(2))
		}else{
			console.log("when we reomve ledger")
			this.setState({auto_accounted:false,auto_ready_accounted:false,auto_ready:false})
			this.props.dispatch(setAccountStatus(oldData.account_status));
			this.props.dispatch(setEntryStatus(oldData.entry_status))
		}
	}


	markAsPersonal = () => {
		this.setState({ is_dirty: true })
		if (this.props.is_personal) {
			this.props.dispatch(setIsPersonal(0))
		} else {
			this.props.dispatch(setIsPersonal(1))
		}
	}

	validateVouchers = () => {
		let valid = true;
		if (this.props.temp_voucher_list.length > 0) {
			this.props.temp_voucher_list.forEach(voucher => {
				if(voucher.voucher_type_guid == null){
					valid = false
					this.setState({vaild_voucher:true})
				}
				if (!voucher.total_credit_amount || !voucher.total_debit_amount) {
					valid = false;
				}
				if (parseFloat(voucher.total_credit_amount.toFixed(2)) !== parseFloat(voucher.total_debit_amount.toFixed(2)))
					valid = false;
			});
		}
		return valid
	}

	removeEmptyVouchersAndLedgers = () => {
		let valid = true, temp_voucher_list = [...this.props.temp_voucher_list];
		if (temp_voucher_list.length > 0) {
			temp_voucher_list.forEach((voucher, index) => {
				// if ((voucher.total_credit_amount === 0 || voucher.total_credit_amount === '')
				// 	&& (voucher.total_debit_amount === 0 || voucher.total_debit_amount === '')
				// 	&& voucher.accounting_narration === ""
				// ) {
				// 	// temp_voucher_list.splice(index, 1)
				// 	// this.props.dispatch(setTempVoucherList(temp_voucher_list))
				// } else {
				let ledgers = voucher.ledgers, temp_ledgers = []
				temp_ledgers = ledgers.filter((ledger, index1) => {
					if (ledger.amount !== 0 && ledger.amount !== '' && (ledger.ledger_id !== 0 && ledger.ledger_id !== '' && ledger.ledger_id !== null)) {
						return ledger
					}
					//console.log(ledger.ledger_id)
					if (ledger.ledger_id === 0 || ledger.ledger_id === '' || ledger.ledger_id == null || ledger.amount === 0 || ledger.amount === '') {
						valid = false
						return ledger
					}
				})
				temp_voucher_list[index].ledgers = temp_ledgers
				this.props.dispatch(setTempVoucherList(temp_voucher_list))
				console.log(valid)
				//}
			});
		}
		return valid
	}

	saveMoneyLink = async (e) => {
		if ((this.props.features_permissions_list.includes(LINKING_CODE) || this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE))) {
			this.props.dispatch(setAppilcationLoder(true));
			//e.preventDefault();
			if (await this.removeEmptyVouchersAndLedgers() === false) {
				toast.warning('Ledger data is missing')
				this.setState((prevState, props) => ({
					submit_click: prevState.submit_click + 1
				}));
				this.setState({ is_valid_ledger: false, save_and_redirect: false, next_route: false })
				this.props.dispatch(setAppilcationLoder(false));
				return
			} else {
				this.props.dispatch(setAppilcationLoder(true));
				if (this.validateVouchers()) {

					let remove_vouchers = await this.handleRemoveVoucher()

					let remove_cost_category = this.state.remove_cost_category;
					console.log(remove_cost_category)
					let remove_cost_center = this.state.remove_cost_center;
					let remove_billwise_allocation = _.cloneDeep(this.state.remove_billwise_allocation);
					//console.log(remove_billwise_allocation)
					let is_valid_ledger = true;
					let billwise_ids = [];

					let linked_evidences = await this.handleLinkEvidence();
					let remove_linked_evidences = await this.handleRemoveEvidence();
					let evidences_added_or_removed = false;
					let to_be_link = [];
					let multiple_billwise_ledgers = [];
					let multiple_billwise_vouchers = [];
					let mismatch_amt = false;
					let autolinking = false;


					let temp_voucher_list = _.cloneDeep(this.props.temp_voucher_list);
					//console.log(temp_voucher_list)
					temp_voucher_list = temp_voucher_list.map((voucher, index) => {
						
						is_valid_ledger = !areGuidsUnique(voucher.ledgers);

						delete voucher.is_leder_recommended;
						delete voucher.sync_error;
						voucher.parent_voucher_type_id = parseInt(voucher.parent_voucher_type_id)
						multiple_billwise_ledgers[index] = 0;

						voucher.ledgers = voucher.ledgers.map((ledger, ledger_index) => {
							if (ledger.cost_categories.length == 1 && !ledger.cost_categories[0].category_id) {
								ledger.cost_categories = [];
								//return ledger;
							}
							if (ledger.cost_categories.length > 0) {
								ledger.cost_categories = ledger.cost_categories.map((category, category_index) => {
									if (!category.category_id || !category.category_guid || !category.category_name) {
										is_valid_ledger = false;
									}
									let cost_center_total = 0;
									let temp_category = {
										"cost_category_id": category.category_id,
										"guid": category.category_guid,
										"name": category.category_name,
										"transaction_voucher_ledger_cost_category_id": category.transaction_voucher_ledger_cost_category_id || 0,
										"cost_centres": category.cost_centers.map((cost_center, cc_index) => {
											if (!cost_center.cost_center_name || !cost_center.cost_center_guid || !cost_center.cost_center_amount) {
												is_valid_ledger = false;
											}
											cost_center_total += parseFloat(cost_center.cost_center_amount);
											let tmp_cost_center = {
												"amount": cost_center.cost_center_amount,
												"cost_centre_id": cost_center.cost_center_id,
												"guid": cost_center.cost_center_guid,
												"name": cost_center.cost_center_name,
												"transaction_voucher_ledger_cost_category_cost_centre_id": cost_center.transaction_voucher_ledger_cost_category_cost_centre_id || 0,
												"type": ledger.type
											}

											if (remove_cost_center.indexOf(cost_center.transaction_voucher_ledger_cost_category_cost_centre_id) != -1) {
												remove_cost_center.splice(remove_cost_center.indexOf(cost_center.transaction_voucher_ledger_cost_category_cost_centre_id), 1);
											}
											return tmp_cost_center;
										})
									}
									if (cost_center_total != ledger.amount) {
										is_valid_ledger = false;
									}

									if (remove_cost_category.indexOf(category.transaction_voucher_ledger_cost_category_id) != -1) {
										remove_cost_category.splice(remove_cost_category.indexOf(category.transaction_voucher_ledger_cost_category_id), 1);
									}
									//console.log(remove_cost_category)
									return temp_category;
								})
							}
							//console.log(ledger.guid)
							//let has_billwise_enable = !!this.state.ledgerNameList.ledger_list.find((temp_ledger) => temp_ledger.guid == ledger.guid && temp_ledger.has_billwise == 1);
							//console.log(has_billwise_enable)
							// if(has_billwise_enable) {

							// 	multiple_billwise_ledgers[index] = multiple_billwise_ledgers[index] + 1;
							// 	//console.log(multiple_billwise_ledgers[index])
							// 	multiple_billwise_vouchers[index] = true;
							// }

							ledger.amount = parseFloat(ledger.amount);

							if (ledger.billwise_allocation.length > 0) {

								multiple_billwise_ledgers[index] = multiple_billwise_ledgers[index] + 1;
								//console.log(multiple_billwise_ledgers[index])
								multiple_billwise_vouchers[index] = true;

								let ledger_amount = ledger.amount;
								let total_billwise_amt = 0;

								ledger.billwise_allocation = ledger.billwise_allocation.map((allocation, allocation_index) => {
									if (allocation.amount != 0 && allocation.type_of_ref != "") {

										total_billwise_amt = (total_billwise_amt + parseFloat(allocation.amount));
										if (allocation.transaction_voucher_ledger_billwise_allocation_id != 0) {
											billwise_ids.push(allocation.transaction_voucher_ledger_billwise_allocation_id);
										}
										//remove_billwise_allocation.splice(remove_billwise_allocation.indexOf(allocation.transaction_voucher_ledger_billwise_allocation_id), 1);
										//console.log(allocation.transaction_voucher_ledger_billwise_allocation_id)
										if (allocation.source_id == undefined) {
											allocation.source_id = null;
										}
										let temp_billwise_allocation = {
											"amount": parseFloat(allocation.amount),
											"invoice_no": allocation.invoice_no,
											"tag_type": allocation.tag_type,
											"transaction_voucher_ledger_billwise_allocation_id": allocation.transaction_voucher_ledger_billwise_allocation_id,
											"type": allocation.type == 0 ? 1 : allocation.type,
											"type_of_ref": allocation.type_of_ref,
											"source": allocation.type_of_ref == 2 ? allocation.source : null,
											"source_id": allocation.type_of_ref == 2 ? allocation.source_id : null
										}
										allocation.amount = parseFloat(allocation.amount);

										allocation.type = allocation.type == 0 ? 1 : allocation.type;
										allocation.source = allocation.type_of_ref == 2 ? allocation.source : null;
										allocation.source_id = allocation.type_of_ref == 2 ? allocation.source_id : null;

										if (allocation.type_of_ref == 2 && allocation.source == 2 && allocation.source_id != "" && (voucher.voucher_type == 2 || voucher.voucher_type == 3)) {
											to_be_link.push(allocation.source_id);
											temp_billwise_allocation.tag_type = 1;
											allocation.tag_type = 1;
										}

										if (voucher.voucher_type == 2 || voucher.voucher_type == 3) {
											autolinking = true;
										}

										_.omit(allocation, ['merchant_id', 'tag'])

										return temp_billwise_allocation;
									} else {
										return []
									}
								})

								//console.log(parseFloat(ledger_amount), parseFloat(total_billwise_amt), total_billwise_amt)
								if (parseFloat(ledger_amount) != parseFloat(total_billwise_amt) && total_billwise_amt != 0) {
									mismatch_amt = true
								}
							}

							return ledger;
						})
						return voucher;
					})
					//console.log(to_be_link)
					console.log("voucher_list",temp_voucher_list)
					if (!is_valid_ledger) {
						this.setState({ is_valid_ledger: false, save_and_redirect: false, next_route: false })
						this.props.dispatch(setAppilcationLoder(false));
						toast.warning('Ledger data is missing')
						return;
					}
					this.props.dispatch(setAppilcationLoder(true));

					let multi = false;
					multiple_billwise_ledgers.forEach(async (ledger_count) => {
						if (ledger_count > 1) {
							multi = true;
						}
					})

					if (multi) {
						this.props.dispatch(setAppilcationLoder(false));
						await confirm({
							confirmation: "Multiple ledgers with billwise enabled found. Unable to do billwise allocation(s).",
							options: { type: "alert" }
						})
						return;
					}
					this.props.dispatch(setAppilcationLoder(true));
					if (mismatch_amt) {
						this.props.dispatch(setAppilcationLoder(false));
						await confirm({
							confirmation: "Total billwise amount should be equal to ledger amount",
							options: { type: "alert" }
						})
						return;
					}

					//console.log(multiple_billwise_vouchers);
					if (multiple_billwise_vouchers.length > 1 && this.props.account_status == 3) {
						this.props.dispatch(setAppilcationLoder(false));
						await confirm({
							confirmation: "Multiple vouchers with billwise allocation(s) found.",
							options: { type: "alert" }
						})
					}
					this.props.dispatch(setAppilcationLoder(true));
					// console.log(autolinking)
					// console.log(to_be_link)
					// console.log('temp_linked_evidences   '+JSON.stringify(this.props.temp_linked_evidences))
					// console.log('linked_evidences === '+JSON.stringify(this.props.linked_evidences))

					if (autolinking) {

						this.props.linked_evidences.forEach(evidence => {
							//console.log(to_be_link.includes(evidence.mod_id))
							if (!to_be_link.includes(evidence.mod_id)) {
								evidences_added_or_removed = true;
								remove_linked_evidences.push({
									"mod_id": evidence.mod_id,
									"module": evidence.module,
									"transaction_evidence_id": evidence.transaction_evidence_id,
								})
							}
						})

						to_be_link.forEach(evd_id => {
							let temp_linked_evidences_index = this.props.temp_linked_evidences.findIndex(evidence => (evidence.mod_id || evidence.id) == evd_id);
							let linked_evidences_index = this.props.linked_evidences.findIndex(evidence => evidence.mod_id == evd_id)

							if (temp_linked_evidences_index == -1 && linked_evidences_index == -1) {
								evidences_added_or_removed = true;
								linked_evidences.push({
									"id": evd_id,
									"module": 1,
								})
							}

							let ind = remove_linked_evidences.findIndex(evidence => evidence.mod_id == evd_id);
							if (ind != -1) {
								evidences_added_or_removed = true;
								remove_linked_evidences.splice(remove_linked_evidences, 1);
							}
						});

						let earlier_length = linked_evidences.length;
						linked_evidences = linked_evidences.filter(evd => to_be_link.includes(evd.id) != false)
						let after_length = linked_evidences.length;

						if (earlier_length != after_length) {
							evidences_added_or_removed = true;
						}
					}
					//console.log(evidences_added_or_removed);

					if (evidences_added_or_removed) {
						this.props.dispatch(setNoSupportingDocument(0))
						this.props.dispatch(setAppilcationLoder(false));
						if (!await confirm({
							confirmation: "Evidences selected and billwise allocation are not consistent. Do you want to system to match it?",
						})) {
							return true;
						}
					}
					//return;
					this.props.dispatch(setAppilcationLoder(true));

					let remove_billwise_ids = _.difference(remove_billwise_allocation, billwise_ids);
					//console.log(remove_billwise_allocation, billwise_ids, remove_billwise_ids)
					let details = {
						"transaction_id": this.state.transaction_id,
						"evidences": _.uniqBy(linked_evidences, 'id'),
						"vouchers": temp_voucher_list,
						"voucher_status": !!temp_voucher_list.length ? 1 : 0,
						"business_narration": this.props.business_narration,
						"is_personal": this.props.is_personal == "" ? 0 : this.props.is_personal,
						"no_supporting_document": evidences_added_or_removed ? 0 : this.props.no_supporting_document,
						//"remove_evidences": remove_linked_evidences,
						"remove_evidences": _.uniqBy(remove_linked_evidences, 'mod_id'),
						"remove_vouchers": remove_vouchers,
						"remove_ledgers": this.state.remove_ledgers,
						"suggested_evidences": [],
						"remove_cost_categories": remove_cost_category,
						"remove_cost_centres": remove_cost_center,
						"accounting_status": parseInt(this.props.account_status) || null,
						"entry_status": parseInt(this.props.entry_status) || null,
						"synced_by_user": (parseInt(this.props.account_status) == 5) ? this.props.user_id : null,
						//"remove_billwise_allocations": remove_billwise_allocation,
						"remove_billwise_allocations": _.uniq(remove_billwise_ids),
					}

					this.setLogData(details, temp_voucher_list, 'new');

					if (this.state.showDeleteVoucherPopup) {
						this.props.dispatch(setAppilcationLoder(false));
						if (!await confirm({
							confirmation: 'You have removed one or more vouchers. Once you delete, they will also be removed from your accounting software. Do you wish to continue?'
						})) {
							return true;
						}
					}
					// console.log(details);
					// this.props.dispatch(setAppilcationLoder(false));
					// return;

					this.props.dispatch(setAppilcationLoder(true));
					// send logs code
					var new_data_log = {
						"no_supporting_document": this.props.no_supporting_document,
						"is_personal": this.props.is_personal,
						"evidences": this.manipulateEvidences(_.cloneDeep(this.props.temp_linked_evidences)),
						"business_narration": this.props.business_narration || '',
						"vouchers": this.manipulateNewVouchersData(_.cloneDeep(temp_voucher_list)),
						"entry_status": this.props.entry_status,
						"accounting_status": this.props.account_status,
					}
			
					//this.props.dispatch(setAppilcationLoder(false));
					// send logs code
					//return

					//this.props.dispatch(setAppilcationLoder(false));
					if (details.entry_status == 1 && details.accounting_status != 3 && this.state.old_data.account_status != 3 && this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE)) {
						// if(details.entry_status == 1 && details.accounting_status != 3) {
						this.props.dispatch(setAppilcationLoder(false));
						if (!await confirm({
							confirmation: "Do you want to change accounting status to Pending?"
						})) {
							this.props.dispatch(setAppilcationLoder(true));
						} else {
							details.accounting_status = 3
							details.synced_by_user = null
							this.props.dispatch(setAppilcationLoder(true));
						}
					}

					if (details.vouchers.length > 0 && details.accounting_status == 5) {
						this.props.dispatch(setAppilcationLoder(false));
						if (!await confirm({
							confirmation: 'This action will save the record but vouchers if any will not be automatically synced with accounting software, do you want to continue?'
						})) {
							return true;
						} else {
							this.props.dispatch(setAppilcationLoder(true));
						}
					}

					//send voucher data empty if this user has no ledger entries (otherwise it will redirect to dashboard)
					if (!this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE)) {
						details.vouchers = []
					}
					//remove voucher_ledger_id property form ledgers
					if (details.vouchers.length > 0) {
						let ledgers = details.vouchers.map((value, key) => {
							return value.ledgers.map((ledger, ledgerKey) => {
								return _.omit(ledger, ['voucher_ledger_id'])
							})
						})
						details.vouchers.forEach((val, key) => {
							//val.ledgers.billwise_allocation = []
							val.ledgers = ledgers[key]
							//details.vouchers[key].billwise_allocation = [];
						})
					}
					//console.log(details)
					//this.props.dispatch(setAppilcationLoder(false));
					// return;
					let transaction_id_found = true;

					let transFound = this.props.transaction_list.findIndex(trans => trans.id == this.props.match.params.transaction_id);

					if (transFound == -1) {
						transaction_id_found = false
					} else {
						transaction_id_found = true;
					}

					this.setState({ save_and_next: !transaction_id_found ? false : this.state.save_and_next })

					MoneyLinkService.saveMoneyLink(details).then(
						async (data) => {
							if (data.response_code == 200) {
								let filter_data = {
									from_date: '',
									to_date: '',
									from_amount: '',
									to_amount: '',
									module: [1],
									vendor: '',
									status: '',
									evidences: []
								}
								this.props.dispatch(setEvidenceFilterData(filter_data));
								
								let trans_data = {
									id: this.state.transaction_id,
									date: this.state.transaction_detail.date,
									amount: this.state.transaction_detail.amount,
									transaction_narration: this.state.transaction_detail.transaction_narration,
									financial_institute: this.state.transaction_detail.financial_institute + " " + (showAliasAcctNo(this.state.transaction_detail.is_credit_card, this.state.transaction_detail.account_number)),
									accounting_status: details.accounting_status,
									entry_status: details.entry_status,
									type: this.state.transaction_detail.type,
									has_evidence: data.result.evidences.length == 0 ? 0 : 1
								}
								this.props.updateTransactionDetail(trans_data)
								//this.props.dispatch(setAppilcationLoder(false));
								this.props.dispatch(setTempVoucherList([]))
								this.setState({auto_accounted:false,auto_ready:false,auto_ready_accounted:false})
								//this.getTransactionDetails();
								//this.clearEvidenceListData()
								this.setState({ is_valid_ledger: true, submit_click: false, is_dirty: false ,vaild_voucher:false})

								console.log("old data log::::",this.state.old_data_log)

								this.props.updateList(this.state.transaction_id, details.entry_status, details.accounting_status, this.props.filter_card, this.props.is_personal, data.result.evidences.length, this.state.save_and_next, this.state.old_data_log.entry_status, this.state.old_data_log.accounting_status,this.state.old_data_log.evidences)

								let status = this.props.account_status > 3 ? parseInt(this.props.account_status) : parseInt(this.props.entry_status)
								this.props.dispatch(setTransactionStatus(parseInt(status)))
								
								toast.success('Transaction saved');

								let delete_log = ""
								if (details.remove_vouchers.length > 0) {
									delete_log += "Voucher with ID(s) ";
									details.remove_vouchers.forEach((voucher_id, index) => {
										if ((details.remove_vouchers.length - 1) == index) {
											delete_log += "#" + voucher_id;
										} else {
											delete_log += "#" + voucher_id + ",";
										}
									})
									delete_log += " deleted";
								}
								console.log(JSON.stringify(this.state.old_data_log), JSON.stringify(this.state.new_data_log))
								let createdLog = createLog.map(this.state.old_data_log, this.state.new_data_log)
								formatLogData(createdLog, ["sync_error", "sync_status"])
								
								sendLog({ module_type: 4, module_id: this.state.transaction_id, attach_activity: delete_log})
								
								let remove_cost_category = [];
								let remove_cost_center = [];
								let remove_billwise_allocation = [];

								let temp_voucher_list = _.cloneDeep(data.result.vouchers);
								//console.log(temp_voucher_list)

								temp_voucher_list = temp_voucher_list.map((voucher, index) => {
									voucher.ledgers = voucher.ledgers.map((ledger, ledger_index) => {
										let empty_cost_category = [{
											"category_guid": "",
											"category_id": 0,
											"category_name": "",
											"transaction_voucher_ledger_cost_category_id": 0,
											"cost_centers": [{
												"cost_center_guid": "",
												"cost_center_id": 0,
												"cost_center_name": "",
												"cost_center_amount": "",
												"transaction_voucher_ledger_cost_category_cost_centre_id": 0
											}]
										}];


										let ledger_detail = this.state.ledgerNameList.ledger_list.find(temp_ledger => {
											return temp_ledger.guid == ledger.guid;
										});

										//console.log(ledger_detail)
										if (ledger_detail != undefined && !ledger.cost_categories.length && ledger_detail.has_cost_centre == 1) {
											ledger.cost_categories = empty_cost_category;
										} else {
											if (ledger.cost_categories.length > 0) {
												ledger.cost_categories = ledger.cost_categories.map((category, category_index) => {
													let temp_category = {
														"category_id": category.cost_category_id,
														"category_guid": category.guid,
														"category_name": category.name,
														"transaction_voucher_ledger_cost_category_id": category.transaction_voucher_ledger_cost_category_id || 0,
														"cost_centers": category.cost_centres.map((cost_center, cc_index) => {
															let tmp_cost_center = {
																"cost_center_amount": cost_center.amount,
																"cost_center_id": cost_center.cost_centre_id,
																"cost_center_guid": cost_center.guid,
																"cost_center_name": cost_center.name,
																"transaction_voucher_ledger_cost_category_cost_centre_id": cost_center.transaction_voucher_ledger_cost_category_cost_centre_id || 0,
																"type": ledger.type
															}
															remove_cost_center.push(cost_center.transaction_voucher_ledger_cost_category_cost_centre_id)
															return tmp_cost_center;
														})
													}
													remove_cost_category.push(category.transaction_voucher_ledger_cost_category_id);
													return temp_category;
												})
											}
										}

										if (ledger_detail != undefined && !ledger.billwise_allocation.length && ledger_detail.has_billwise == 1) {
											ledger.billwise_allocation = []
										} else {
											//console.log(ledger.billwise_allocation.length, ledger_detail.has_billwise)
											if (ledger.billwise_allocation.length > 0) {

												ledger.billwise_allocation = ledger.billwise_allocation.map((allocation, allocation_index) => {

													let tag = "";

													if (allocation.type_of_ref == 1) {
														tag = "<b>" + allocation.invoice_no + "</b> (₹" + (allocation.amount) + ")";
													} else if (allocation.type_of_ref == 2) {
														tag = allocation.invoice_no + " (₹" + (allocation.amount) + ")";
													} else if (allocation.type_of_ref == 3) {
														tag = "Adv. (₹" + (allocation.amount) + ")";
													} else if (allocation.type_of_ref == 4) {
														tag = "On Acc. (₹" + (allocation.amount) + ")";
													}

													let temp_billwise_allocation = {
														"amount": allocation.amount,
														"invoice_no": allocation.invoice_no,
														"tag_type": allocation.tag_type,
														"transaction_voucher_ledger_billwise_allocation_id": allocation.transaction_voucher_ledger_billwise_allocation_id,
														"type": allocation.type,
														"type_of_ref": allocation.type_of_ref,
														"merchant_id": "",
														"tag": tag,
														"source": allocation.source,
														"source_id": allocation.source_id
													}
													remove_billwise_allocation.push(allocation.transaction_voucher_ledger_billwise_allocation_id);
													return temp_billwise_allocation;
												})

											}
										}

										return ledger;
									})
									//console.log(voucher)
									return voucher;
								})
								
								this.props.dispatch(setTempVoucherList(temp_voucher_list))


								// store old data for log
								// let oldData = {};
								// oldData.no_supporting_document = this.state.no_supporting_document;
								// oldData.is_personal = this.state.is_personal;
								// oldData.evidences = this.manipulateEvidences(this.state.evidences);
								// oldData.business_narration = this.state.business_narration || '';
								// oldData.vouchers = this.manipulateVouchersData(_.cloneDeep(temp_voucher_list));
								// oldData.accounting_status = this.state.accounting_status;
								// oldData.entry_status = this.state.entry_status;
								
								this.setState({ voucher_list_from_db: data.result.vouchers })

								this.props.dispatch(setLinkedEvidenceList(data.result.evidences));
								this.props.dispatch(setTempLinkedEvidenceList(data.result.evidences));

								this.setState({ remove_cost_category, remove_cost_center, remove_billwise_allocation});
								details.evidences = data.result.evidences;
								this.setLogData(details, temp_voucher_list, 'old');

								
								//reset search evidence filter
								this.applyFilter("reset");

								this.props.dispatch(setAppilcationLoder(false));
								console.log(this.state.save_and_next)
								if (this.state.save_and_next) {

									let list = [...this.props.transaction_list]
									let index = this.props.transaction_list.findIndex(transaction => transaction.id == this.state.transaction_id);

									//console.log(list)
									//console.log(index)
									if (this.state.communication_bar_tasks_dirty) {
										this.comment_section.saveData('tasks', this.state.next_route);
									} else if (this.state.communication_bar_msgs_dirty) {
										this.comment_section.saveData('msgs', this.state.next_route);
									} else if (this.state.communication_bar_private_msgs_dirty) {
										this.comment_section.saveData('private_msgs', this.state.next_route);
									} else if (this.state.communication_bar_notes_dirty) {
										this.comment_section.saveData('notes', this.state.next_route);
									} else {
										//this.props.history.push(this.state.next_route)

										let next_trasaction = list[index + 1];
										if (!!next_trasaction) {
											//this.props.history.push('/money-link/' + next_trasaction.id)
											this.props.history.push({
												pathname: '/money-link/' + next_trasaction.id,
												state: {
													save_and_next: true
												}
											});
										} else {
											this.props.moveToNextPage(index)
										}
									}
								} else {
								}
							} else {

								if (data.response_code == 400) {
									if (!!data.reason.accounting_status) {
										this.props.dispatch(setAppilcationLoder(false));
										await confirm({
											confirmation: "The record is modified by other user and it’s status is changed. The data will be refreshed now.",
											options: { type: "alert" }
										})
										this.getTransactionDetails()
										return
									}
								}
								toast.error(data.message);
								this.props.dispatch(setAppilcationLoder(false));
								this.setState({ save_and_redirect: false, next_route: false })
							}
						});

				}
				else {
					this.setState({ is_valid_voucher: false, save_and_redirect: false, next_route: false })
					this.props.dispatch(setAppilcationLoder(false));
				}
			}
		}
	}

	removeLedger = (id) => {
		if (id != 0) {
			let to_be_remove = this.state.remove_ledgers
			to_be_remove.push(id)
			this.setState({ remove_ledgers: to_be_remove, is_dirty: true })
		}
	}

	removeAllEvidences = () => {
		let remove_evidences = this.props.linked_evidences.map((evidence, index) => {
			return {
				"mod_id": evidence.mod_id,
				"module": evidence.module,
				"transaction_evidence_id": evidence.transaction_evidence_id,
			}
		})
		this.setState({
			toggleNoDocumentConfirm: false,
			remove_evidences: remove_evidences,
		})
		this.props.dispatch(setTempLinkedEvidenceList([]));
	}

	closePopup = () => {
		if (this.state.toggleNoDocumentConfirm) {
			this.props.dispatch(setNoSupportingDocument(1))
			this.setState({ toggleNoSupportingDocument: true }, () => {
				this.removeAllEvidences()
			})
		}
	}

	confirmRemove = (confirm) => {
		if (confirm) {
			this.closePopup()
		} else {
			this.setState({ toggleNoDocumentConfirm: false })
		}
	}

	handleRemoveEvidence = () => {
		this.setState({ is_dirty: true })
		let temp_linked_evidences = this.props.temp_linked_evidences, linked_list = this.props.linked_evidences
		let to_be_remove = linked_list.filter((evidence, index1) => {
			let index = temp_linked_evidences.findIndex(temp_linked_evidences => temp_linked_evidences.mod_id == evidence.mod_id);
			if (index == -1)
				return evidence
			//delete linked_list[index1]
			//return evidence
		})

		return to_be_remove.map((evi, index) => {
			return {
				"mod_id": evi.mod_id,
				"module": evi.module,
				"transaction_evidence_id": evi.transaction_evidence_id,
			}
		})

	}

	handleRemoveVoucher = async () => {
		let temp_voucher_list = [...this.props.temp_voucher_list], temp = [];
		let voucher_list = [...this.state.voucher_list_from_db]
		//console.log(voucher_list);
		let showDeleteVoucherPopup = false;
		if (voucher_list.length > 0) {
			let to_be_remove = voucher_list.filter((voucher, index1) => {
				let index = temp_voucher_list.findIndex(temp_voucher =>
					(temp_voucher.voucher_id || temp_voucher.transaction_voucher_ledger_id) == (voucher.voucher_id || voucher.transaction_voucher_ledger_id));
				if (index == -1)
					return voucher
			})


			temp = to_be_remove.map((voucher, index) => {
				if (voucher.transaction_voucher_ledger_id != 0) {
					if (voucher.sync_status == 1 || voucher.sync_status == 2) {
						showDeleteVoucherPopup = true;
					}
					return voucher.voucher_id
				}
			})

			this.setState({ showDeleteVoucherPopup })

			for (var i = 0; i < temp.length; i++) {
				if (temp[i] == 0)
					temp.splice(i, 1);
			}
			return temp

		} else {

			this.setState({ showDeleteVoucherPopup });
			return temp

		}
	}

	handleLinkEvidence = (type = null) => {
		if (type != "first_time")
			this.setState({ is_dirty: true })

		let temp_linked_evidences = _.cloneDeep(this.props.temp_linked_evidences);
		//console.log(temp_linked_evidences)

		let data = []
		temp_linked_evidences.forEach((evidence, index) => {
			if (evidence.hasOwnProperty('id')) {
				console.log({
					id: evidence.id,
					module: evidence.module,
				})
				data.push({
					id: evidence.id,
					module: evidence.module
				})
			}
		})

		return data
	}

	getValue = () => {
		return this.props.transaction_details.status
	}

	handleSaveAndNext = (e) => {
		this.setState({ save_and_next: e.target.checked })
	}

	isFormDirty = () => {
		this.setState({ is_dirty: true })
	}

	getLogs = () => {
		this.props.dispatch(setAppilcationLoder(true));
		let detail = { mod_id: this.state.transaction_id, module: 4 }
		MasterService.getLogs(detail).then((data) => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				this.setState({ logs_list: data.result.change_logs })
			} else {
				toast.error(data.message)
			}
			this.setState({ show_log: true })
		})
	}

	toggleChangeLog = () => {
		this.setState({ show_log: !this.state.show_log })
	}

	toggleHelp = () => {

		this.setState({ show_help: !this.state.show_help })
	}

	closeTransactionLinking = () => {
		this.setState({ closed: true }, function () {
			return <RouteLeavingGuard
				when={this.state.is_dirty}
				closed={this.state.closed}
				closeAction={this.closeAction}
			/>
		})
	}

	saveBeforeLeave = (nextRoute) => {
		this.setState({ save_and_redirect: true, next_route: nextRoute }, function () {
			if (this.state.is_dirty) {
				this.preSaveMoneyLink()
			} else if (this.state.communication_bar_tasks_dirty) {
				this.setState({ communication_bar_tasks_dirty: false })
				this.comment_section.saveData('tasks', nextRoute);
			} else if (this.state.communication_bar_notes_dirty) {
				this.setState({ communication_bar_notes_dirty: false })
				this.comment_section.saveData('notes', nextRoute);
			} else if (this.state.communication_bar_msgs_dirty) {
				this.setState({ communication_bar_msgs_dirty: false })
				this.comment_section.saveData('msgs', nextRoute);
			} else if (this.state.communication_bar_private_msgs_dirty) {
				this.setState({ communication_bar_private_msgs_dirty: false })
				this.comment_section.saveData('private_msgs', nextRoute);
			}
		});
	}

	closeAction = () => {
		this.setState({ closed: false })
	}

	preSaveMoneyLink = () => {
		if ((this.state.old_data.status == 5 || this.state.old_data.status == 3) && (this.props.transaction_status == 6 || this.props.transaction_status == 1 || this.props.transaction_status == 2)) {
			this.setState({ status_reverted: true })
		} else if (this.props.temp_voucher_list.length == 0 && (this.props.account_status == 4) && (this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE))) {
			this.setState({ process_no_ledgers: true })
		} else {
			this.saveMoneyLink();
		}
	}

	confirmChangeStatus = (is_confirm) => {
		if (is_confirm) {
			this.saveMoneyLink()
		} else {
			this.props.dispatch(setTransactionStatus(this.state.old_data.status))
			this.setState({ status: this.state.old_data.status })
		}
		this.setState({ status_reverted: false })
	}

	processWithNoLedgers = (is_confirm) => {
		if (is_confirm) {
			this.saveMoneyLink()
		}
		this.setState({ process_no_ledgers: false })
	}

	search_toggle = () => {
		this.setState({ show_filter: !this.state.show_filter })
	}

	//comminication bar functions
	update_communication_bar_tasks_dirty = (status) => {
		this.setState({ communication_bar_tasks_dirty: status })
	}

	update_communication_bar_notes_dirty = (status) => {
		this.setState({ communication_bar_notes_dirty: status })
	}

	update_communication_bar_msgs_dirty = (status) => {
		this.setState({ communication_bar_msgs_dirty: status })
	}

	update_communication_bar_private_msgs_dirty = (status) => {
		this.setState({ communication_bar_private_msgs_dirty: status })
	}

	// componentWillUnmount() { console.log('unmount')
	// 	StorageData.deleteTaskManagerId()
	// } 

	// Intro js user guid   
	toggleSteps = () => {
		this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
	};
	onExit = () => {
		this.setState(() => ({ stepsEnabled: false }));
	};
	// End Intro js user guid

	manipulateVouchersData = (vouchers) => {

		let formated_vouchers = vouchers.map((voucher, key) => {

			delete voucher.sync_datetime;
			delete voucher.total_credit_amount;
			delete voucher.total_debit_amount;
			delete voucher.voucher_id;
			delete voucher.voucher_type;

			voucher.voucher_date = voucher.voucher_creation_date;
			delete voucher["voucher_creation_date"];

			voucher.ledgers = voucher.ledgers.map((ledger) => {
				delete ledger["guid"];
				delete ledger["transaction_voucher_ledger_id"];
				delete ledger["ledger_id"];
				delete ledger["type"];
				if (!!ledger["voucher_ledger_id"]) {
					delete ledger["voucher_ledger_id"];
				}

				ledger.ledger = ledger.name;
				delete ledger["name"];

				ledger.ledger_amount = ledger.amount;
				delete ledger["amount"];

				if (ledger.cost_categories !== undefined) {

					ledger.cost_categories.map((cost_category) => {

						delete cost_category["category_guid"];
						delete cost_category["category_id"];
						delete cost_category["cost_category"];
						delete cost_category["transaction_voucher_ledger_cost_category_id"]

						cost_category.cost_category = cost_category.category_name;
						delete cost_category["category_name"];

						if (cost_category.cost_centers !== undefined) {

							cost_category.cost_centers.map((cost_centre) => {
								//console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkk')
								delete cost_centre["cost_center_guid"];
								delete cost_centre["cost_center_id"];
								delete cost_centre["transaction_voucher_ledger_cost_category_cost_centre_id"];

								cost_centre.cost_center_type = cost_centre.type;
								delete cost_centre["type"];

								cost_centre.cost_center = cost_centre.cost_center_name;
								delete cost_centre["cost_center_name"]

								return cost_centre;
							})
						}


						return cost_category;
					})
				}

				return ledger;
			})

			return voucher;
		});
		//console.log(formated_vouchers)
		return formated_vouchers;
	}

	manipulateNewVouchersData = (vouchers) => {

		let formated_vouchers = vouchers.map((voucher, key) => {

			delete voucher.sync_datetime;
			delete voucher.total_credit_amount;
			delete voucher.total_debit_amount;
			delete voucher.voucher_id;
			delete voucher.voucher_type;

			voucher.voucher_date = voucher.voucher_creation_date;
			delete voucher["voucher_creation_date"];

			voucher.ledgers = voucher.ledgers.map((ledger) => {
				delete ledger["guid"];
				delete ledger["transaction_voucher_ledger_id"];
				delete ledger["ledger_id"];
				delete ledger["type"];
				delete ledger["voucher_ledger_id"];


				ledger.ledger = ledger.name;
				delete ledger["name"];

				ledger.ledger_amount = ledger.amount;
				delete ledger["amount"];

				if (ledger.cost_categories !== undefined) {

					ledger.cost_categories.map((cost_category) => {

						delete cost_category["cost_category"];
						delete cost_category["transaction_voucher_ledger_cost_category_id"];
						delete cost_category["guid"];
						delete cost_category["cost_category_id"];

						cost_category.cost_category = cost_category.name;
						delete cost_category["name"];

						cost_category.cost_centers = cost_category.cost_centres;
						delete cost_category["cost_centres"]


						if (cost_category.cost_centers !== undefined) {

							cost_category.cost_centers.map((cost_centre) => {
								delete cost_centre["guid"];
								delete cost_centre["cost_centre_id"];
								delete cost_centre["transaction_voucher_ledger_cost_category_cost_centre_id"];

								cost_centre.cost_center_type = cost_centre.type;
								delete cost_centre["type"];

								cost_centre.cost_center = cost_centre.name;
								delete cost_centre["name"]

								cost_centre.cost_center_amount = cost_centre.amount;
								delete cost_centre["amount"]

								return cost_centre;
							})
						}
						return cost_category;
					})
				}

				return ledger;
			})

			return voucher;
		});
		//console.log(formated_vouchers)
		return formated_vouchers;
	}

	manipulateEvidences = (evidences) => {
		let formated_evidences = []
		evidences.map((evidence, key) => {
			formated_evidences.push(evidence.transaction_evidence_id)
		})

		return formated_evidences;
	}

	getMoneyLinkLedgerRecommendation = async () => {

		if (this.props.temp_voucher_list.length) {
			if (await confirm({
				confirmation: 'This action will remove existing vouchers. Do you want to continue?'
			})) {
				this.checkAccountedStatus(false)
				this.props.dispatch(setTempVoucherList([]))
			} else {
				return true;
			}
		}

		let merchant_id = null;
		//console.log(this.props.temp_linked_evidences)
		if (this.props.temp_linked_evidences.length > 0) {
			merchant_id = this.props.temp_linked_evidences[0].merchant_id
		}
		this.setState({ ledger_recommendation_loading: true })
		//this.props.dispatch(setAppilcationLoder(true));
		MoneyLinkService.getMoneyLinkLedgerRecommendation(this.state.transaction_id, merchant_id)
			.then(async (data) => {
				//this.props.dispatch(setAppilcationLoder(false));
				if (data.response_code == 200) {
					if (Object.keys(data.result.vouchers).length == 0) {
						toast.error("No recommended Ledger Found.");
					} else {
						///this.setState({ is_leder_recommended: 1 });
						let voucher = data.result.vouchers;

						let temp_voucher = {
							"accounting_narration": voucher.accounting_narration,
							"parent_voucher_type_id":voucher.parent_voucher_type_id,
							"voucher_type_guid":voucher.voucher_type_guid,
							"voucher_type_name":voucher.voucher_type_name || "Select voucher type",
							"voucher_type_id":voucher.voucher_type_id,
							"voucher_id": 0,
							"voucher_creation_date": voucher.voucher_creation_date,
							"total_credit_amount": voucher.total_credit_amount,
							"total_debit_amount": voucher.total_debit_amount,
							"is_leder_recommended": 1,
							"sync_status": 0,
							"sync_datetime": changeDateTimeFormat(new Date()),
							"sync_error": voucher.sync_error,
							"ledgers": voucher.ledgers.map((ledger, ledger_index) => {
								ledger.transaction_voucher_ledger_id = 0;
								let empty_cost_category = [{
									"category_guid": "",
									"category_id": 0,
									"category_name": "",
									"transaction_voucher_ledger_cost_category_id": 0,
									"cost_centers": [{
										"cost_center_guid": "",
										"cost_center_id": 0,
										"cost_center_name": "",
										"cost_center_amount": "",
										"transaction_voucher_ledger_cost_category_cost_centre_id": 0
									}]
								}];
								//ledger.cost_categories = []
								let is_cost_category_enable = !!this.state.ledgerNameList.ledger_list.find((temp_ledger) => temp_ledger.guid == ledger.guid && temp_ledger.has_cost_centre == 1);
								//console.log(is_cost_category_enable, ledger.ledger_id, ledger.cost_categories)
								if (!ledger.cost_categories.length && is_cost_category_enable) {
									ledger.cost_categories = empty_cost_category;

								} else {
									ledger.cost_categories = ledger.cost_categories.map((category, category_index) => {
										let temp_category = {
											"category_id": category.cost_category_id,
											"category_guid": category.guid,
											"category_name": category.name,
											"transaction_voucher_ledger_cost_category_id": category.voucher_ledger_cost_category_id || 0,
											"cost_centers": category.cost_centres.map((cost_center, cc_index) => {
												let tmp_cost_center = {
													"cost_center_amount": cost_center.amount,
													"cost_center_id": cost_center.cost_centre_id,
													"cost_center_guid": cost_center.guid,
													"cost_center_name": cost_center.name,
													"transaction_voucher_ledger_cost_category_cost_centre_id": cost_center.voucher_ledger_cost_category_cost_centre_id || 0,
													"type": ledger.type
												}
												//remove_cost_center.push(cost_center.voucher_ledger_cost_category_cost_centre_id)
												return tmp_cost_center;
											})
										}
										console.log(temp_category)
										//remove_cost_category.push(category.transaction_voucher_ledger_cost_category_id);
										return temp_category;
									})
								}

								ledger.billwise_allocation = []

								return ledger;
							})
						}
						//return temp_voucher;
						// });

						await this.props.dispatch(setTempVoucherList([temp_voucher]))
						this.checkForReadyAccountedState()
						// this.checkAccountedStatus(true)

					}
					this.setState({ ledger_recommendation_loading: false })
				} else {
					toast.error(data.message);
				}
			});

	}

	setLogData = (data, temp_voucher_list, type) => {
		let log_data = {};
		log_data.mark_it_personal = data.is_personal;
		log_data.no_supporting_document = data.no_supporting_document;
		log_data.evidences = this.manipulateEvidences(data.evidences);
		log_data.business_narration = data.business_narration || '';
		log_data.vouchers = type == 'new' ? this.manipulateNewVouchersData (_.cloneDeep(temp_voucher_list)) : this.manipulateVouchersData(_.cloneDeep(temp_voucher_list));
		log_data.accounting_status = data.accounting_status;
		log_data.entry_status = data.entry_status;

		if(type == 'new') {
			this.setState({new_data_log: log_data})
		} else {
			this.setState({old_data_log: log_data})
		}
		
	}

	resetTransaction = async() =>{
		let showDeleteVoucherPopup = false;
		let showDeleteEvidencePopup = false;
		let activity = ['Reset Transaction'];
		let delete_log = ""
		if (this.state.voucher_list_from_db.length > 0) {
			delete_log += "Voucher with ID(s) ";
			this.state.voucher_list_from_db.forEach((voucher, index) => {
				if ((this.state.voucher_list_from_db.length - 1) == index) {
					delete_log += "#" + voucher.voucher_id;
				} else {
					delete_log += "#" + voucher.voucher_id + ",";
				}
				if (voucher.sync_status == 1 || voucher.sync_status == 2) {
					showDeleteVoucherPopup = true;
				}
			})
			delete_log += " deleted";
			activity.push(delete_log)
		}
		if(this.props.temp_linked_evidences.length > 0){
			delete_log = "";
			delete_log += "Evidence with ID(s) ";
			this.props.temp_linked_evidences.forEach((evidence, index) => {
				if ((this.props.temp_linked_evidences.length - 1) == index) {
					delete_log += "#" + evidence.transaction_evidence_id;
				} else {
					delete_log += "#" + evidence.transaction_evidence_id + ",";
				}
				if (evidence.status == 3 || evidence.status == 4 || evidence.status == 5) {
					showDeleteEvidencePopup = true;
				}
			})
			delete_log += " deleted";
			activity.push(delete_log)
		}


		if (showDeleteVoucherPopup || showDeleteEvidencePopup) {
			this.props.dispatch(setAppilcationLoder(false));
			if (!await confirm({
				confirmation: 'Once you reset the transaction, vouchers and evidences will also be removed from your accounting software. Do you wish to continue?'
			})) {
				return true;
			}
		} else {
			if (!await confirm({
				confirmation: "Are you sure, you want to reset it?"
			})) {
				return true
			}
		}


		this.props.dispatch(setAppilcationLoder(true));
		MoneyLinkService.resetTransaction(this.state.transaction_id).then(data => {
			this.props.dispatch(setAppilcationLoder(false));
			if (data.response_code === 200) {
				toast.success('Transaction reseted')

				// send log code
				sendLog({ module_type: 1, module_id: this.state.transaction_id, activity: activity })
				// send log code

				this.setState({ is_dirty: false }, () => {
					this.getTransactionDetails()
				})
			} else {
				toast.error(data.reason.error_message)
			}
			// this.toggleDeletePopup()
		})
	}

	render() {

		// Intro js user guid 
		const {
			stepsEnabled,
			steps,
			initialStep,
			hintsEnabled,
			hints
		} = this.state;
		// End Intro js user guid  

		const transaction_status = this.props.transaction_status;
		const entry_status = this.props.entry_status;
		const account_status = this.props.account_status;

		let is_dirty = false
		if (this.state.is_dirty) {
			is_dirty = true
		} else if (this.state.is_dirty && (this.state.communication_bar_tasks_dirty || this.state.communication_bar_notes_dirty || this.state.communication_bar_msgs_dirty || this.state.communication_bar_private_msgs_dirty)) {
			is_dirty = true
		} else if (!this.state.is_dirty && (this.state.communication_bar_tasks_dirty || this.state.communication_bar_notes_dirty || this.state.communication_bar_msgs_dirty || this.state.communication_bar_private_msgs_dirty)) {
			is_dirty = true
		} else {
			is_dirty = false
		}
		const taskManagerId = localStorage.getItem('task_manager_task_id') || null;


		return (
			// <div className="col-xl-9 col-lg-8 col-md-7 col-sm-7 main-content-sec">
			<div className="col-xl-9 col-lg-8 col-md-8 col-sm-7 main-content-sec-new">

				{/* Intro js user guid  */}
				<Steps
					enabled={stepsEnabled}
					steps={steps}
					initialStep={initialStep}
					onExit={this.onExit}
				/>
				{/* End Intro js user guid  */}

				{this.state.saveData === false ?
					<RouteLeavingGuard
						when={is_dirty}
						saveChanges={this.saveBeforeLeave}
						closed={this.state.closed}
						closeAction={this.closeAction}
					/>
					: null
				}
				<ConfirmPopup
					toggleConfirmPopup={this.state.toggleNoDocumentConfirm}
					message="All linked evidences will be discarded. Do you want to still continue ?"
					confirmRemove={this.confirmRemove}
				/>
				{this.state.status_reverted && <ConfirmPopup
					toggleConfirmPopup={this.state.status_reverted}
					message="Link Evidence status will be reverted and form will be editable. Do you want to still want to continue?"
					confirmRemove={this.confirmChangeStatus}
				/>}
				{this.state.process_no_ledgers && <ConfirmPopup
					toggleConfirmPopup={this.state.process_no_ledgers}
					message="No ledger entries specified. Do you want to proceed?"
					confirmRemove={this.processWithNoLedgers}
				/>}
				<ChangeLog
					show_log={this.state.show_log}
					toggleChangeLog={this.toggleChangeLog}
					logs={this.state.logs_list}
				/>
				<HelpModal
					show_help={this.state.show_help}
					toggleHelp={this.toggleHelp}
					logs={this.state.logs_list}
				/>
				<>
					{
						this.state.report_url &&
						<ViewEvidenceReport
							show_modal={this.state.view_evidence}
							report_url={this.state.report_url}
							closeModal={this.toggleLinkHistoryPopup}
						/>
					}
				</>
				<LinkHistoryView
					show_modal={this.state.show_link_history}
					link_history_data={this.state.link_history_data}
					link_history_evidence={this.state.link_history_evidence}
					link_history_money_link={this.state.link_history_money_link}
					closeModal={this.toggleLinkHistoryPopup}
				/>

				<div className="main-content-inner-new">
					{/* Sub Header Inner */}
					<div className="sub-header-inner-new">
						<div className="row">
							<div className="col-sm-12">
								<div className="sub-header-lr-new">
									<div className="shleft-new">
										<h3 className="heading-shi">Link Transactions</h3>
										<div onClick={this.toggleSteps}>
											<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Help</Tooltip>}>
												<span class="help-icon"><span class="icon-Information help-icon"></span></span>
											</OverlayTrigger>
										</div>
										<div className="multi-new-badges">
											<div className={this.state.is_dirty ? "modified badges-new-text" : "saved badges-new-text"}>
												{this.state.is_dirty ? "Modified" : "Saved"}
											</div>
										</div>
									</div>

									<div className="shright-new">
										<div className="sh-new-error-text">
											{
												(!this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) && this.state.read_only && (account_status != 3)) &&
												<FormControl>
													<p className="red-text error_right mb-0">This record is read only. Contact admin if you wish to edit</p>
												</FormControl>
											}
										</div>

										<h3 className="heading-shi mb-0">
											<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reload</Tooltip>}>
												<Link onClick={async (e) => {
													e.preventDefault()
													if (this.state.is_dirty) {
														if (!await confirm({
															confirmation: 'You will lost unsaved data. Do you want to refresh ?'
														})) {
															return true;
														}
													}
													this.setState({auto_accounted:false,auto_ready:false,auto_ready_accounted:false})
													this.getTransactionDetails()
													this.getLedgerMaster();
													this.getVoucherTypeList()
												}
												}><span class="icon-refresh green-icon"></span>
												</Link>
											</OverlayTrigger>

											<Link onClick={this.getLogs}>
												<span className="icon-round-info-button blue-icon"></span>
											</Link>
											<Link to={'/money-link'} onClick={() => this.closeTransactionLinking()}>
												<span className="icon-Cross-with-grey-circle "><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
											</Link>
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* End Sub Header Inner */}

					<TransactionDetails
						// transaction_detail={this.state.transaction_detail}
						markAsPersonal={this.markAsPersonal}
						read_only={this.state.read_only}
					/>

					<div className="form-content-sec-new">
						<div className="evi-row-col">
							<LinkedEvidences
							checkReadyStatus = {this.checkReadyStatus}
								toggleNoSupportingDocument={this.state.toggleNoSupportingDocument}
								// handleRemoveEvidence={this.handleRemoveEvidence}
								editEvidence={this.editEvidence}
								viewEvidence={this.viewEvidence}
								showLinkHistory={this.showLinkHistory}
								isFormDirty={this.isFormDirty}
								read_only={this.state.read_only}
								transaction_amount={Math.abs(this.props.transaction_details.amount)}
								//setAllocationData={}
								backup_evidences={this.state.backup_evidences}
								getMoneyLinkLedgerRecommendation={this.getMoneyLinkLedgerRecommendation}
							/>
							<UnlinkedEvidences
								checkReadyStatus = {this.checkReadyStatus}
								viewEvidence={this.viewEvidence}
								showLinkHistory={this.showLinkHistory}
								toggleSaveData={this.toggleSaveData}
								handleLinkEvidence={this.handleLinkEvidence}
								toggleNoSupportingDocument={this.state.toggleNoSupportingDocument}
								//suggested_evidence_list={this.state.suggested_evidence_list}
								per_page_items={this.state.per_page_items}
								evidence_tab={this.state.evidence_tab}
								ledgerNameList={this.state.ledgerNameList}
								setPageNo={this.setPageNo}
								changeEvidenceTab={this.changeEvidenceTab}
								searchFilter={this.searchFilter}
								applyFilter={this.applyFilter}
								editEvidence={this.editEvidence}
								removeLedger={this.removeLedger}
								isFormDirty={this.isFormDirty}
								is_valid_voucher={this.state.is_valid_voucher}
								is_valid_ledger={this.state.is_valid_ledger}
								transaction_id={this.props.match.params.transaction_id}
								read_only={this.state.read_only}
								transactionDate={this.props.transaction_details.date}
								transactionAmount={Math.abs(this.props.transaction_details.amount)}
								transaction_narration={this.props.transaction_details.transaction_narration}
								transactionType={this.props.transaction_details.type}
								show_filter={this.state.show_filter}
								search_toggle={this.search_toggle}
								submit_click={this.state.submit_click}
								transaction_status={transaction_status}
								checkAccountedStatus={this.checkAccountedStatus}
								account_status={account_status}
								ledger_entry_permission={this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE)}
								getMoneyLinkLedgerRecommendation={this.getMoneyLinkLedgerRecommendation}
								saveAllocationData={(allocation_data) => this.setState({ billwise_allocation: allocation_data })}
								backup_evidences={this.state.backup_evidences}
								old_account_status={this.state.old_data.account_status}
								getLedgerMaster={this.getLedgerMaster}
								getVoucherTypeList = {this.getVoucherTypeList}
								features_permissions_list={this.props.features_permissions_list}
								markAsNoSupportingDocument={this.markAsNoSupportingDocument}
								no_supporting_document={this.props.no_supporting_document}
								ledger_recommendation_loading={this.state.ledger_recommendation_loading}
								evidence_list_loading={this.state.evidence_list_loading}
								voucherTypeList = {this.state.voucherTypeList}
								vaild_voucher = {this.state.vaild_voucher}
							/>
						</div>
					</div>

					{/* Buttons */}
					<div className="row mt-3">
						<div className="col-sm-12">
							<div className="page-button-bar">
								<div className="btn-flex-left mr-lg-auto">
									{this.state.task_manager_task_id &&
										<Button variant="outline-success reset_btn_new" onClick={() => this.redirectToTaskManager()}>Back</Button>
									}
										{this.state.transaction_id !== 0 &&
										<>
											{
												(this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) || this.props.features_permissions_list.includes(LINKING_CODE)) &&
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Transaction Reset</Tooltip>}>
													{/* <span class="icon-delete blue-icon btn-bar-delete" onClick={this.resetTransaction}></span> */}
													<span class="icon-Group-3587 reset-bar-icon" onClick={this.resetTransaction}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
												</OverlayTrigger>
											}
										</>
									}

									<div className="sup-cust-check btn-bar-save-text">
										<input className="styled-checkbox" id="styled-checkbox-11" type="checkbox"
											name="save_and_next" checked={this.state.save_and_next}
											onChange={(e) => {
												this.handleSaveAndNext(e)
											}} />
										<label for="styled-checkbox-11" className="label-text"><span className='smtext'>Save and move to next</span></label>
									</div>
								</div>

								<div className="btn-flex-right">
									<div className="global_new_rn_dd redyDraft_moneyin">
										{(this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) || this.props.features_permissions_list.includes(LINKING_CODE)) &&
											<FormControl>
												<Select
													value={entry_status}
													onChange={this.handleEntryStatus}
													name="entry_status"
													className={"mr-0 " + (this.state.auto_ready || this.state.auto_ready_accounted ? " global_new_dd_selected" : "")}
													disabled={(!this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) && this.state.read_only && (account_status != 3))}
												>
													<MenuItem value={1} disabled={!this.props.features_permissions_list.includes(LINKING_CODE)}>Draft</MenuItem>
													<MenuItem value={2} disabled={!this.props.features_permissions_list.includes(LINKING_CODE)}>Ready</MenuItem>
												</Select>
											</FormControl>
										}
									</div>

									{(this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE)) &&
										<div className="global_new_rn_dd status_moneyin">
											<FormControl>
												<Select
													value={account_status}
													onChange={this.handleAccountStatus}
													name="account_status"
													className={"mr-0 " + (this.state.auto_accounted ? " global_new_dd_selected" : "")}
												>
													<MenuItem value={3} disabled={!this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE)}>Pending</MenuItem>
													<MenuItem value={4} disabled={this.props.features_permissions_list.includes(LINKING_CODE) && !this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) ? true : false}>Accounted</MenuItem>
													<MenuItem value={5} disabled={true}>Synced </MenuItem>
												</Select>
											</FormControl>
										</div>
									}
									<Button variant="success apply_btn_new save_moneyin" onClick={this.preSaveMoneyLink} disabled={(!this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) && this.state.read_only && (account_status != 3))}>Save</Button>
								</div>
							</div>
						</div>
					</div>
					{/* End Buttons */}

					<span className='commbar_moneyin'>
						<CommentSection
							context_no={4}
							context_name={"money_link"}
							view_only={this.VIEW_LINKED_ENTRIES_CODE}
							context_id={this.state.transaction_id}
							update_communication_bar_tasks_dirty={this.update_communication_bar_tasks_dirty}
							communication_bar_tasks_dirty={this.state.communication_bar_tasks_dirty}
							update_communication_bar_notes_dirty={this.update_communication_bar_notes_dirty}
							communication_bar_notes_dirty={this.state.communication_bar_notes_dirty}
							update_communication_bar_msgs_dirty={this.update_communication_bar_msgs_dirty}
							communication_bar_msgs_dirty={this.state.communication_bar_msgs_dirty}
							update_communication_bar_private_msgs_dirty={this.update_communication_bar_private_msgs_dirty}
							communication_bar_private_msgs_dirty={this.state.communication_bar_private_msgs_dirty}
							onRef={ref => (this.comment_section = ref)} />
					</span>

				</div>
			</div>
		)
	}
}
function mapStateToProps(state) {
	const all_value = state.MoneyLink || {};
	const from_amount = all_value.evidence_filter_data.from_amount || '';
	const to_amount = all_value.evidence_filter_data.to_amount || '';
	const from_date = all_value.evidence_filter_data.from_date || '';
	const to_date = all_value.evidence_filter_data.to_date || '';
	const module = all_value.evidence_filter_data.module || ''
	const vendor = all_value.evidence_filter_data.vendor || '';
	const status = all_value.evidence_filter_data.status;
	const evidences = all_value.evidence_filter_data.evidences || [];

	const linked_evidences = all_value.linked_evidences;
	const temp_linked_evidences = all_value.temp_linked_evidences;
	const is_personal = all_value.is_personal;
	const no_supporting_document = all_value.no_supporting_document;
	const business_narration = all_value.business_narration;
	const transaction_status = all_value.transaction_status;
	// const voucher_list = all_value.voucher_list;
	const temp_voucher_list = all_value.temp_voucher_list;
	const transaction_details = all_value.transaction_details

	const suggested_evidence_list = all_value.suggested_evidence_list || []
	const suggested_evidence_ids = all_value.suggested_evidence_ids || []

	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	const ledger_recommendation_param = session_value.entity_config_param.ledger_recommendation || 0;
	const user_id = session_value.user_detail.id
	const entry_status = all_value.entry_status;
	const account_status = all_value.account_status;

	return {
		from_date, from_amount, to_amount, to_date, module, vendor, status, evidences,
		linked_evidences, is_personal, no_supporting_document, business_narration,
		transaction_status, temp_linked_evidences, temp_voucher_list, transaction_details,
		features_permissions_list, suggested_evidence_list, suggested_evidence_ids, user_id,
		entry_status, account_status, ledger_recommendation_param
	};
}

export default connect(mapStateToProps)(withRouter(LinkedTransactionsForm));