import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import DocboxListView from './DocboxListView';
import DocboxService from '../../Services/Docbox/DocboxService';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import axios from 'axios';

class DocboxView extends Component {

	constructor(props) {
		super(props);

		this.PERMISSION_LIST = this.props.features_permissions_list;

		this.state = {
			docbox_list: [],
			snapshot_files: [],
			show_add_folder_popup: false,
			show_bulk_upload_popup: false,
			show_delete_popup: false,
			parent_id: null,
			current_folder_name: "",
			crum_path: "",
			page_no: 1,
			mod_type: 1,
			sort_by: 1,
      		sort_order: 2,
			path_array: [],
			has_next: false
		}
	}

	componentDidMount() {
		this.getDocboxList();
		this.getSnapshotFiles();
		//this.createFileFolder();
	}

	getDocboxList = () => {
		this.props.dispatch(setAppilcationLoder(true));
		
		let filter_data = {
			"per_page_items": this.props.per_page_items,
			"page_no": this.state.page_no,
			"parent_id": this.state.parent_id,
			"sort_by": parseInt(this.state.sort_by),
			"sort_order": this.state.sort_order
		}

		DocboxService.getDocboxList(filter_data)
		.then((data) => { 
			console.log(this.state.docbox_list.length)
			if (data.response_code == 200) {
				this.setState({
					docbox_list: this.state.docbox_list.length == 0 ? data.result.docbox : [...this.state.docbox_list, ...data.result.docbox],
					has_next: data.result.has_next,
					has_previous: data.result.has_previous,
					page_no: data.result.page_no,
					total_count: data.result.total_count,
					total_pages: data.result.total_pages,
					per_page_items: data.result.per_page_items,
				},() => {
					this.props.dispatch(setAppilcationLoder(false));
				})
			} else {
				this.props.dispatch(setAppilcationLoder(false));
				toast.error(data.message);
			}
		})
	}

	getSnapshotFiles = () => {
		let filter_data = {
			"per_page_items": this.props.per_page_items,
			"page_no": this.state.page_no,
			"mod_type": this.state.mod_type,
			"sort_by": parseInt(this.state.sort_by),
			"sort_order": this.state.sort_order
		}
		DocboxService.getDocboxSystemFiles(filter_data)
			.then((response) => {
				console.log('response',response)
				if (response.response_code === 200) {
					this.setState({ snapshot_files: response.result.snapshot_files });
				} else {
					toast.error(response.message);
				}
			})
			.catch((error) => {
				console.error("Error fetching snapshot files:", error);
				toast.error("Failed to load snapshot files");
			});
	}

	addNewFolder = (data) => {
		this.setState({
			docbox_list: [data, ...this.state.docbox_list]
		})	
	}

	finishFilesUpload = (list) => {
		console.log(this.state.docbox_list, list)
		this.setState({
			docbox_list: [...list, ...this.state.docbox_list]
		}, () => {
			// if(this.state.docbox_list.length > 25) {
			// 	this.setState({has_next: true})
			// }
		})	
	}

	toggleAddFolderPopup = () => {
		this.setState({show_add_folder_popup: !this.state.show_add_folder_popup})
	}

	toggleBulkUploadPopup = () => {
		this.setState({show_bulk_upload_popup: !this.state.show_bulk_upload_popup})
	}

	toggleDeletePopUp = () => {
		// if(this.state.show_delete_popup) {
		// 	let elem = document.getElementsByClassName('er-pop');
		// 	elem[0].classList.remove('show');

		// 	let elems = document.getElementsByClassName('modal-backdrop');
		// 	elems[0].remove();
		// 	// <div class="modal-backdrop fade show"></div>
		// 	return
		// } else {
			this.setState({show_delete_popup: !this.state.show_delete_popup})
		//}
	}

	makeRemoveStar = (docbox_id, changed_star) => {
		let docbox_list = this.state.docbox_list;
		let index = docbox_list.findIndex(docbox => docbox.id == docbox_id);
		let docbox = docbox_list[index];
		docbox.is_star = changed_star;
		docbox_list[index] = docbox;
		this.setState({docbox_list})
	}

	goInsideFolder = (row) => {
		let path_array = this.state.path_array;
		path_array.push({
			'id': row.id,
			'name': row.name
		})

		const isCustomFolder = row.is_custom === true;

		// this.setState({parent_id: row.id, current_folder_name: row.name, path_array, docbox_list: [], page_no: 1, sort_by: 1, sort_order: 2}, () => {
		// 	console.log(this.state.page_no)
		// 	this.getDocboxList();
		// })
		this.setState({
			mod_type: isCustomFolder ? 1 : row.mod_type,
			parent_id: isCustomFolder ? null : row.id,
			current_folder_name: row.name,
			path_array,
			docbox_list: [],
			snapshot_files: [],
			page_no: 1,
			sort_by: 1,
			sort_order: 2,
		}, () => {
			if (isCustomFolder) {
				this.getSnapshotFiles();
			} else {
				this.getDocboxList();
			}
		});
	}

	changePathByBreadCrum = (data) => {
		let path_array = this.state.path_array;
		let new_path_array = [];
		
		if(data.id != null){
			for (const value of path_array) {
				new_path_array.push(value)
				if (value.id == data.id) {
					break;
				}
			}
		}

		this.setState({parent_id: data.id, current_folder_name: data.name, page_no: 1, path_array: new_path_array, docbox_list: [], sort_by: 1,
			sort_order: 2}, () => {
			this.getDocboxList();
		})
	}

	getCrumbPath = () => {
		let path_array = this.state.path_array;
		let crumb_path = "";

		path_array.forEach((arr, index) => {
			crumb_path += `${arr.name}$${arr.id}`;
			if(path_array.length != (index+1)){
				crumb_path += "~"
			}
		});

		return crumb_path;
	}

	onSortClick = (sort_order, sort_by) => {
		this.setState({
		  sort_order,
		  sort_by,
		  page_no: 1,
		  docbox_list: [],
		  snapshot_files: []
		}, () => {
			this.getDocboxList();
			this.getSnapshotFiles();
		});
	}

	deleteFileFolder = (id) => {
		this.props.dispatch(setAppilcationLoder(true));
		DocboxService.deleteFileFolder(id).then(data => {
			this.props.dispatch(setAppilcationLoder(false));
			this.toggleDeletePopUp()
			if (data.response_code === 200) {
				
				toast.success(data.message)
				
				let docbox_list = this.state.docbox_list;
				let index = docbox_list.findIndex(data => data.id == id);
				docbox_list.splice(index, 1);
				this.setState({docbox_list})
				//this.getSnapshotList();
			} else {
				toast.error(data.reason.error_message)
			}
			
			
		})
	}

	refresh = () => {
		this.setState({page_no: 1, docbox_list: []}, () => {
			this.getDocboxList();
		})
	}

	handleNextChange = () => {
		this.setState({page_no: this.state.page_no + 1}, () => {
			this.getDocboxList();
		})
	}

	downloadFile = async(file, file_name) => {
		// console.log('file, file_name',file, file_name)
		const link = document.createElement('a');
        link.href = file;
        link.setAttribute(
            'download',
            file_name,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
	}

	render() {
		return (
			<div>
				{/* <ScrollPage /> */}
				<Switch>  
					<Route path="/docbox"
					render={() => {
						return <DocboxListView
							docbox_list={this.state.docbox_list}
							snapshot_files={this.state.snapshot_files}
							toggleAddFolderPopup={this.toggleAddFolderPopup}
							show_add_folder_popup={this.state.show_add_folder_popup}
							show_bulk_upload_popup={this.state.show_bulk_upload_popup}
							toggleBulkUploadPopup={this.toggleBulkUploadPopup}
							addNewFolder={this.addNewFolder}
							parent_id={this.state.parent_id}
							finishFilesUpload={this.finishFilesUpload} 
							makeRemoveStar={this.makeRemoveStar}
							goInsideFolder={this.goInsideFolder}
							current_folder_name={this.state.current_folder_name}
							getCrumbPath={this.getCrumbPath}
							changePathByBreadCrum={this.changePathByBreadCrum}
							path_array={this.state.path_array}
							mod_type={this.state.mod_type}
							sort_by={this.state.sort_by}
							sort_order={this.state.sort_order}
							onSortClick={this.onSortClick}
							deleteFileFolder={this.deleteFileFolder}
							show_delete_popup={this.state.show_delete_popup}
							toggleDeletePopUp={this.toggleDeletePopUp}
							refresh={this.refresh}
							has_next={this.state.has_next}
							handlePageChange={this.handleNextChange}
							downloadFile={this.downloadFile}
							features_permissions_list={this.props.features_permissions_list}
							system_parameter={this.props.system_parameter}
							user_id={this.props.user_id}
						/>
						}
					}
					exact />
					{/* <Redirect to="/welcome" /> */}
				</Switch>
			</div>
		)
	}
}

function mapStateToProps(state) {

	const session_values = state.Session || {};
	
	const system_parameter = session_values.system_parameter || {};
	const per_page_items = system_parameter.per_page_items || 0;
	//const per_page_items = 5;
	const features_permissions_list = session_values.features_permissions_list || [];
	const user_id = session_values.user_detail.id
	
	return {
		per_page_items, features_permissions_list, system_parameter, user_id
	};
}

export default connect(mapStateToProps)(DocboxView);