import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import SnapshotService from '../../Services/Snapshot/SnapshotService';
import MasterService from '../../Services/MasterService';
import StorageData from "../../Helpers/StorageData";
import { changeDateFormate, dateWithMonthName } from '../../Helpers/DateFormat';
import SnapshotList from './SnapshotList';
import CreateSnapshotView from './CreateSnapshotView';
import { setSnapshotCardFilter, setSnapshotFinancialYearname, setSnapshotLoadingStatus, setSnapshotWidgets, smsSetPageNo,smsSetPagePerItem, smsSetTotalCount, setSnapshotDefaultWidgets, setPageNo, setFilterDateRange, setSnapshotSearchText, setBulkSnapshotList } from "../../Redux/Actions/Snapshot";
import SMSView from './SMSView';
import { setAppilcationLoder } from "../../Redux/Actions/App";
import { CREATE_BUSSINESS_SNAPSHOT_CODE,MANAGE_OTHER_USER_SNAPSHOT_CODE,VIEW_ONLY_SNAPSHOT_CODE,CREATE_REIMBUSRMENT_SNAPSHOT_CODE,
  APPROVE_REIMBURSMENT_SNAPSHOT_CODE,ACCESS_BUSSINESS_SMS_SNAPSHOT_CODE,SMART_SCAN_SNAPSHOT_CODE } from '../../Helpers/Constants';
import ScrollPage from '../../Components/BoilerPlate/ScrollPage';
import { toTitleCase } from '../../Helpers/Capitalization';
import { Redirect } from 'react-router'
import { setSnapshotFilterData } from '../../Redux/Actions/Snapshot';
import BulkAction from './BulkAction';
import { Button,Modal } from 'react-bootstrap';
import { confirm } from 'react-confirm-box';
import SnapshotQuickEntry from './SnapshotQuickEntry';
import SnapshotQuickView from './SnapshotQuickView';
import { confirm as oldconfirm } from '../../Components/BoilerPlate/Confirm';
// import SnapshotQuickView from './SnapshotQuickView copy';


const options = {
  render: (message, onConfirm, onCancel) => {
      return (
          <>
              {/* <h1> Replace with {message} </h1>
          <button onClick={onConfirm}> Yes </button> */}
              <Modal
                  show={true}
                  backdrop="static"
                  keyboard={false}
                  centered
                  // className="save-model-sec er-pop"
                  className="popup-model error-popup"
              >
                  <Modal.Body className="clearfix ">
                      <p className="pmsg-blue-center">
                          Alert
                      </p>
                      <p className="pmsg-text pmsg-gray-center">
                          {message}
                      </p>

                      <div className="center_apply_btn_new">
                          <Button onClick={onCancel} variant="outline-success reset_btn_new">No</Button>
                          <Button onClick={onConfirm} variant="success apply_btn_new">Yes</Button>
                      </div>
                  </Modal.Body>

                  {/* <Modal.Footer className='bottom-save-btn'>
                      <Button onClick={onCancel} variant="" className={"btn model-save-btn save-btn graybtn alert"}>No</Button>
                      <Button onClick={onConfirm} variant="" className="btn save-btn greenbtn model-save-btn">Yes</Button>
                  </Modal.Footer> */}
              </Modal>
          </>
      );
  }
};

class SnapshotView extends Component {

  constructor(props) {
    super(props);
		
		this.PERMISSION_LIST = this.props.features_permissions_list;
		this.CREATE_BUSSINESS_SNAPSHOT = this.PERMISSION_LIST.includes(CREATE_BUSSINESS_SNAPSHOT_CODE);
		this.MANAGE_OTHER_USER_SNAPSHOT = this.PERMISSION_LIST.includes(MANAGE_OTHER_USER_SNAPSHOT_CODE);
		this.VIEW_ONLY_SNAPSHOT = this.PERMISSION_LIST.includes(VIEW_ONLY_SNAPSHOT_CODE);
		this.CREATE_REIMBUSRMENT_SNAPSHOT = this.PERMISSION_LIST.includes(CREATE_REIMBUSRMENT_SNAPSHOT_CODE);
		this.APPROVE_REIMBURSMENT_SNAPSHOT = this.PERMISSION_LIST.includes(APPROVE_REIMBURSMENT_SNAPSHOT_CODE);
		this.ACCESS_BUSSINESS_SMS_SNAPSHOT = this.PERMISSION_LIST.includes(ACCESS_BUSSINESS_SMS_SNAPSHOT_CODE);
		this.SMART_SCAN_SNAPSHOT = this.PERMISSION_LIST.includes(SMART_SCAN_SNAPSHOT_CODE);
		
		this.VIEW_ONLY_SNAPSHOT = this.VIEW_ONLY_SNAPSHOT && 
				!(this.CREATE_REIMBUSRMENT_SNAPSHOT || this.MANAGE_OTHER_USER_SNAPSHOT) ;

    let default_yr = StorageData.getDefaultFinancialYear();

    this.props.dispatch(setAppilcationLoder(true));
    
    this.state = {
      snapshot_list: [],
      creaters: [],
      financial_institute_list: [],
      listed_tags: [],
      categories: [],
      financial_institutes: [],
      snapshot_types: [],
      filter_by: this.props.filter_by || 0,
      total_count: 0,
      per_page_items: this.props.per_page_items,
      page_no: 1,
      year_type: default_yr.year_type,
      // start_date: default_yr.year_type != 6 ? default_yr.start_date : "",
      // end_date: default_yr.year_type != 6 ? default_yr.end_date: "",
      start_date: default_yr.year_type ? default_yr.start_date : "",
      end_date: default_yr.year_type ? default_yr.end_date: "",
      filter_card: window.location.href.includes('/quick-entry') ? 3 : 0,
      filter: 0,
      sort_by: 1,
      sort_order: 2,
      show_filter_popup: false,
      sms_has_next: false,
      sms_has_previous: false,
      sms_page_no: 1,
      sms_total_count: 0,
      sms_total_pages: 0,
      sms_per_page_items: this.props.per_page_items,
      sms_sort_by: 1,
      sms_sort_order: 1,
      sms_filter_by: 0,
      sms_search: '',
      classification: null,
      is_search:0,
      isSearchApplied: false,
      default_cards : StorageData.getDefaultSnapshotCards() || {
        accounting_card: 3,
        synched_card: 5,
        reimbursement_approval_card: 7,
        linking_card: 9
      },
      date_type: StorageData.getDateType(),
      save_and_nexts: false,
      quick_entry : 0
    };

    //this.props.dispatch(setSnapshotLoadingStatus(true));
    //this.props.dispatch(setAppilcationLoder(true));
    this.props.dispatch(setSnapshotLoadingStatus(false));
  }

  getSmsList = (filter_by = this.state.sms_filter_by) => {
    return true;
  }

  financialYearChanged = async(year_type, start_date, end_date) => { 
  //   if (await this.checkBulkFlag()) {
  //     return true;
  // }
    //if (!(this.state.year_type == year_type && this.state.start_date == start_date, this.state.end_date == end_date)) {
    if(year_type == 6 && start_date == "" && end_date == ""){
      this.setState({
        year_type:year_type,
        start_date:"",
        end_date:""
      })
    }

    else{
      this.setState({
        year_type,
        start_date,
        end_date,
        page_no: 1
      }, () => {
        this.getSnapshotList();
        this.getSmsList();
      })
     
    //}
    }   

    let default_yr = StorageData.getFinancialYear();
    let name = default_yr ? default_yr.find(x => x.id == year_type).name : "";
    this.props.dispatch(setSnapshotFinancialYearname(name));

    if (year_type == 6) {
      this.props.dispatch(setFilterDateRange(start_date, end_date))
    }
  }

  refreshItemCreate = () =>{
    this.getSnapshotList();
  }
  quickEntryRefreshItem = async() =>{
    if (await this.checkQuickFlag()) {
      return true;
  }
    this.getSnapshotList();
  }
  refreshItem = async() => {
    if (await this.checkBulkFlag()) {
      return true;
  }
    this.getSnapshotList();
  }

  smsFinancialYearChanged = (year_type, start_date, end_date) => {
    if (!(this.state.year_type == year_type && this.state.start_date == start_date, this.state.end_date == end_date)) {
      this.setState({
        year_type,
        start_date,
        end_date,
        sms_page_no: 1
      }, () => {
        this.getSmsList();
      })
    }
  }

  smsRefreshItem = () => {
    this.getSmsList();
  }

  handleTabChange = (e) => {
    const { value, name } = e.target;
    if (value == 1) {
      this.props.history.push('/snapshot/evidence')
    } else {
      this.props.history.push('/snapshot/sms')
    }
  }
  handleQuickEntryTabChange = async(value) =>{
    if(value == 1){
      await oldconfirm({
        confirmation: "You can only do Quick Entry for Sales, Purchase, Sales Return and Purchase Return Snapshots which does not have vouchers and in Ready + Pending status.",
        options: { type: "alert" }
    })
        let default_cards = this.state.default_cards;
        default_cards.accounting_card = 3;
        StorageData.setDefaultSnapshotCards(default_cards)
        this.setState({
          default_cards: default_cards,
          filter_card: 3,
      })
        this.props.dispatch(setSnapshotCardFilter(3));
        this.setState({ quick_entry: value }, () => {
          this.getSnapshotList();
          this.props.history.push('/snapshot/quick-entry');
      });
        
    }

  }

  handleTabQuickView = async(value) =>{
    if(value == 1){
        let default_cards = this.state.default_cards;
        default_cards.accounting_card = 3;
        StorageData.setDefaultSnapshotCards(default_cards)
        this.setState({
          default_cards: default_cards,
          filter_card: 3,
      })
        this.props.dispatch(setSnapshotCardFilter(3));
        this.setState({ quick_entry: value }, () => {

          this.getSnapshotList();
          console.log("inside this page")
          this.props.history.push('/snapshot/quick-entry');
      });
    }

  }

  onCardSelected = (task_card_filter) => {
    //console.log(task_card_filter)
    let default_cards = this.state.default_cards;

		if(task_card_filter == 3 || task_card_filter == 4){
			default_cards.accounting_card = task_card_filter;
		} else if(task_card_filter == 5 || task_card_filter == 6) {
			default_cards.synched_card = task_card_filter;
		} else if(task_card_filter == 7 || task_card_filter == 8) {
			default_cards.reimbursement_approval_card = task_card_filter;
		} else if(task_card_filter == 9 || task_card_filter == 10) {
			default_cards.linking_card = task_card_filter;
    }
    
    StorageData.setDefaultSnapshotCards(default_cards)
    
    this.setState({
      default_cards: default_cards,
      filter_card: task_card_filter,
      page_no: 1
    }, () => this.getSnapshotList())
    console.log(this.state.default_cards)
    this.props.dispatch(setSnapshotCardFilter(task_card_filter))
  }
  quicEntryOnSortClick = async(sort_order, sort_by) => {
    if (await this.checkQuickFlag()) {
      return true;
  }
    this.setState({
      sort_order,
      sort_by,
      page_no: 1
    }, () => this.getSnapshotList())
  }
  onSortClick = async(sort_order, sort_by) => {
    if (await this.checkBulkFlag()) {
      return true;
  }
    this.setState({
      sort_order,
      sort_by,
      page_no: 1
    }, () => this.getSnapshotList())
  }
  onSmsSortClick = (sort_order, sort_by) => {
    this.setState({
      sms_sort_order: sort_order,
      sms_sort_by: sort_by
    }, () => this.getSmsList())
  }

  quickEntrySearchFilter = async(search) => {
    if (await this.checkQuickFlag()) {
      return true;
  }
    this.setState({
      page_no: 1,
      is_search : !!search ? 1 : 0,
      // isSearchApplied: true,
    }, () => this.getSnapshotList(1))
  }

  searchFilter = async(search) => {
    if (await this.checkBulkFlag()) {
      return true;
  }
    this.setState({
      page_no: 1,
      is_search : !!search ? 1 : 0,
      // isSearchApplied: true,
    }, () => this.getSnapshotList(1))
  }
  quickEntryApplyFilter = async(type) => {
    if (await this.checkQuickFlag()) {
      return true;
  }
    this.setState({
      page_no: 1
    }, () => {
      type == "apply" ? this.getSnapshotList(2) : this.getSnapshotList(0);
    })
  }
  applyFilter = async(type) => {
    if (await this.checkBulkFlag()) {
      return true;
  }
    this.setState({
      page_no: 1
    }, () => {
      type == "apply" ? this.getSnapshotList(2) : this.getSnapshotList(0);
    })
  }

  getSnapshotData = () => {
    SnapshotService.getSnapshotData().then(
      (data) => {
        this.setState({
          isloading: false
        })
        if (data.response_code == 200) {
          this.setState({
            creaters: data.result.created_by,
            categories: [...data.result.categories, ...this.state.categories],
            listed_tags: [...data.result.tags, ...this.state.listed_tags],
            financial_institutes: data.result.financial_institutes,
            snapshot_types: data.result.snapshot_types
          });
        } else {
          toast.error(data.message);
        }
      });
  }

  handlePageChange = (page, sizePerPage) => {
    this.setState({
      page_no: page
    }, () => this.getSnapshotList())
  }

  getSnapshotList = (filter_by = this.state.filter_by, is_export = 0) => { 
    this.setState({
      filter_by: filter_by,
      snapshot_list: []
    })
    this.props.dispatch(setSnapshotLoadingStatus(true));
    this.props.dispatch(setAppilcationLoder(true));

    let filter_data = {
      "per_page_items": this.state.per_page_items,
      "page_no": this.state.page_no,
      "date_filter": {
        "start_date": changeDateFormate(this.state.start_date),
        "end_date": changeDateFormate(this.state.end_date),
        "date_type": this.state.date_type
      },
      "filter_card": this.state.filter_card,
      "filter": filter_by,
      "search": this.props.search_text,
      "filter_box": {
        "type": !!this.props.snapshot_type ? parseInt(this.props.snapshot_type) : null,
        "snapshot_number": !!this.props.snapshot_number ? this.props.snapshot_number : null,
        "supplier_name": !!this.props.supplier_name ? this.props.supplier_name : null,
        "from_amount": !!this.props.from_amount ? this.props.from_amount : null,
        "to_amount": !!this.props.to_amount ? this.props.to_amount : null,
        "payment_status": !!this.props.payment_status ? parseInt(this.props.payment_status) : null,
        "created_by": !!this.props.created_by ? this.props.created_by : null,
        "is_star": this.props.is_star !== '' ? parseInt(this.props.is_star) : null,
        "voucher_status": this.props.voucher_status !== '' ? parseInt(this.props.voucher_status) : null,
        "tags": !!this.props.tags ? [this.props.tags] : [],
        "category": !!this.props.category_id ? this.props.category_id : null,
        "payment_method": null,
        "source": this.props.source !== '' ? parseInt(this.props.source) : null,
        "attachment_status" : this.props.attachment_status !== '' ? parseInt(this.props.attachment_status) : null,
        "gst_status" : this.props.gst_status !== '' ? parseInt(this.props.gst_status) : null,
        "messages": this.props.messages !== '' ? parseInt(this.props.messages) : null, 
        "tds_status": this.props.tds_status !== '' ? parseInt(this.props.tds_status) : null,
        "is_entity_mismatch": this.props.is_entity_mismatch != null ? parseInt(this.props.is_entity_mismatch) : null
      },
      "is_export":is_export,
      "sort_by": this.state.sort_by,
      "sort_order": this.state.sort_order,
      "quick_entry":this.state.quick_entry,
    };

    //console.log(filter_data)
		if(this.props.payment_method != "0_0"){
			let payment_method = this.props.payment_method.split("_");
			filter_data.filter_box.payment_method = {"method": parseInt(payment_method[0]), "id": payment_method[1] != 0 ? parseInt(payment_method[1]) : null}			
		}

    SnapshotService.getSnapshotList(filter_data).then((data) => {
      this.props.dispatch(setSnapshotLoadingStatus(false));
      this.props.dispatch(setAppilcationLoder(false));

      //START - EXPORT LIST CODE
      if(is_export == 1){
          //check if response is null i.e if bytelenght is or less than 5
           if (data.byteLength > 5) {
              console.log()
               var dataa = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
               let url = window.URL.createObjectURL(dataa);
               let a = document.createElement('a');
               a.href = url;
               let entityName = toTitleCase(this.props.entity_name)
               a.download = "Buktec_"+entityName+"_SNAP_"+dateWithMonthName(this.state.start_date)+"-"+dateWithMonthName(this.state.end_date)+".xlsx";
               a.click();

               this.refreshItem()
           } else {
               toast.error("No snapshot found");
           }
      }
      //END - EXPORT LIST CODE

      if (data.response_code == 200) {
        this.props.dispatch(setSnapshotWidgets(data.result.snapshot_counts));

        this.setState({
          snapshot_list: data.result.snapshots,
          has_next: data.result.has_next,
          has_previous: data.result.has_previous,
          page_no: data.result.page_no,
          total_count: data.result.total_count,
          total_pages: data.result.total_pages,
          per_page_items: data.result.per_page_items,
        },() => {
          if (this.state.save_and_nexts) {
            //this.props.history.push('/snapshot/evidence/create/' + data.result.snapshots[0].id)
            this.props.history.push({
              pathname: '/snapshot/evidence/create/' + data.result.snapshots[0].id,
              state: {
                  save_and_next: true
              }
            });
          }
        });

      } else {
        toast.error(data.message);
      }
    })}

  handleSearch = (value) => {
    this.setState({ sms_search: value }, () => {
      this.getSmsList(1)
    })
  }


  handleClassification = (value) => {
    this.setState({ classification: value }, () => {
      this.getSmsList(1)
    })
  }

  componentWillReceiveProps(nextProps) {
    console.log("inside next props",this.props.location.pathname)
    console.log("inside next props",nextProps.location.pathname)
    if ((this.props.location.pathname == '/snapshot/quick-entry') && (nextProps.location.pathname == '/snapshot/evidence' || nextProps.location.pathname == '/snapshot/bulk-action' || nextProps.location.pathname == '/snapshot/evidence/create' || nextProps.location.pathname == '/snapshot') ){
        this.setState({
            page_no: 1,
            quick_entry: 0
        }, () => {
            this.props.dispatch(setBulkSnapshotList([]))
            this.getSnapshotList()
            // this.getTransactionWidgetCount()
        })
    }
    if ((this.props.location.pathname == '/snapshot/quick-entry-create') && (nextProps.location.pathname == '/snapshot/evidence' || nextProps.location.pathname == '/snapshot/bulk-action' || nextProps.location.pathname == '/snapshot/evidence/create' || nextProps.location.pathname == '/snapshot') ){
      this.setState({
          page_no: 1,
          quick_entry: 0
      }, () => {
          this.props.dispatch(setBulkSnapshotList([]))
          this.getSnapshotList()
          // this.getTransactionWidgetCount()
      })
  }

  if ((this.props.location.pathname == '/snapshot/quick-entry-create') && (nextProps.location.pathname == '/snapshot/quick-entry' ) ){
    this.setState({
        quick_entry: 1
    }, () => {
        this.getSnapshotList()
        // this.getTransactionWidgetCount()
    })
}
}

componentDidUpdate(prevProps, prevState) {
  if (prevProps.page_no != this.props.page_no) {
      this.setState({
          page_no: this.props.page_no
      }, () => {
          this.getSnapshotList()
          // this.getTransactionWidgetCount()
      })
  }
}
  componentDidMount() {
    let quick_entry = 0;
    if (this.props.location.pathname == '/snapshot/quick-entry') {
      quick_entry = 1;
  }
  if(this.props.location.pathname == '/snapshot/quick-entry-create'){
    quick_entry = 1;
  }
    this.setState({quick_entry},()=>{
      if (this.state.start_date != "") {

        let default_yr = StorageData.getFinancialYear();
        let name = default_yr ? default_yr.find(x => x.id == this.state.year_type).name : "";
        this.props.dispatch(setSnapshotFinancialYearname(name));

        //custom date range is available .pushing date to filter date range
        let yr = default_yr.filter(x => x.id == this.state.year_type)
        console.log(yr)
  
        if(yr.length > 0){
          if(yr[0].id == 6){
            console.log("here")
            this.props.dispatch(setFilterDateRange((yr[0]).start_date,yr[0].end_date))
          }
        }
  
        this.getSnapshotList();
        this.getSmsList();
      }
      this.props.dispatch(setBulkSnapshotList([]))
      this.getSnapshotData();
    })
    // if (this.state.start_date != "") {

    //   let default_yr = StorageData.getFinancialYear();
    //   let name = default_yr ? default_yr.find(x => x.id == this.state.year_type).name : "";
    //   this.props.dispatch(setSnapshotFinancialYearname(name));

    //   this.getSnapshotList();
    //   this.getSmsList();
    // }
    // this.getSnapshotData();
    let filter_data = {
			is_star: "", category_id: "", customer_name: "", from_amount: "", to_amount: "", snapshot_type: "",
			payment_status: "", created_by: "", payment_method: "0_0", tags: "", supplier_name: "", snapshot_number: "",
			voucher_status: "", source: "", is_entity_mismatch: "", attachment_status: "", messages: "", tds_status: "", gst_status: ""
		}
		this.props.dispatch(setSnapshotFilterData(filter_data));
    this.props.dispatch(setSnapshotSearchText(""));
  }

  makeRemoveStar = (snapshot_id, changed_star) => {
    let snapshot_list = this.state.snapshot_list;
    let index = snapshot_list.findIndex(snapshot => snapshot.id == snapshot_id);
    let snapshot = snapshot_list[index];
    snapshot.is_star = changed_star;
    snapshot_list[index] = snapshot;
    this.setState({
      snapshot_list
    })
  }

  removeSnapshot = (snapshot_id) => {
    let snapshot_list = this.state.snapshot_list;
    let index = snapshot_list.findIndex(data => data.id == snapshot_id);
    snapshot_list.splice(index, 1);
    this.setState({
      snapshot_list: snapshot_list
    })
    this.getSnapshotList();
  }

  newSnapshot = (snapshot, is_update, save_and_next = false) => {
    console.log("new snapshot snapshot",snapshot)
    //alert(is_update)
    let snapshot_list = this.state.snapshot_list;
    let index = snapshot_list.findIndex(data => data.id == snapshot.id);
    if (is_update == 1 || is_update == 2) {
      snapshot_list[index] = snapshot;
      this.setState({
        snapshot_list: snapshot_list
      })

      if(is_update != 2) {
        let widget_count = this.props.widget_count;

        if(snapshot.old_entry_status != snapshot.entry_status) {
          if(snapshot.old_entry_status == 1) {
            widget_count.draft = (parseInt(widget_count.draft) - 1);
          } else if(snapshot.old_entry_status == 2) {
            widget_count.ready = (parseInt(widget_count.ready) - 1);
          }
        }

        if(snapshot.old_accounting_status != snapshot.accounting_status) {
          if(snapshot.old_accounting_status == 3) {
            widget_count.pending = (parseInt(widget_count.pending) - 1);
          } else if(snapshot.old_accounting_status == 4) {
            widget_count.accounted = (parseInt(widget_count.accounted) - 1);
          } else if (snapshot.old_accounting_status == 5) {
            widget_count.synced_by_user = (parseInt(widget_count.synced_by_user) - 1);
          }
        }
        
        //add count
        if(snapshot.old_entry_status != snapshot.entry_status) {
          if(snapshot.entry_status == 1) {
            widget_count.draft = (parseInt(widget_count.draft) + 1);
          } else if(snapshot.entry_status == 2) {
            widget_count.ready = (parseInt(widget_count.ready) + 1);
          }
        }

        if(snapshot.old_accounting_status != snapshot.accounting_status) {
          if(snapshot.accounting_status == 3) {
            widget_count.pending = (parseInt(widget_count.pending) + 1);
          } else if(snapshot.accounting_status == 4) {
            widget_count.accounted = (parseInt(widget_count.accounted) + 1);
          } else if (snapshot.accounting_status == 5) {
            widget_count.synced_by_user = (parseInt(widget_count.synced_by_user) + 1);
          }
        }

        if(snapshot.old_approval_status != snapshot.approval_status) {
          if(snapshot.approval_status == 1 && snapshot.old_approval_status == null && snapshot.old_entry_status == 2) {
            widget_count.approved = (parseInt(widget_count.approved) + 1);
            widget_count.for_approval = (parseInt(widget_count.for_approval) - 1);
          } else if(snapshot.approval_status == 1 && snapshot.old_approval_status == 2 ) {
            widget_count.approved = (parseInt(widget_count.approved) + 1);
          }
          else if (snapshot.approval_status == 2 && snapshot.old_approval_status == 1 ){
            widget_count.approved = (parseInt(widget_count.approved) - 1);
          }else if (snapshot.approval_status == 2 && snapshot.old_approval_status == null && snapshot.old_entry_status == 2){
            widget_count.for_approval = (parseInt(widget_count.for_approval) - 1);
          }else if(snapshot.approval_status == 1 && snapshot.old_approval_status == null && snapshot.old_entry_status == 1){
            widget_count.approved = parseInt((widget_count.approved)+1);
          }
        }
        if(snapshot.old_approval_status == null && snapshot.approval_status == null){
           if(snapshot.old_approval_status == null && snapshot.approval_status == null && snapshot.is_reimbursement && snapshot.entry_status == 2 && snapshot.old_entry_status == 1){
            widget_count.for_approval = (parseInt(widget_count.for_approval) + 1);
          } else if(snapshot.old_approval_status == null && snapshot.approval_status == null && snapshot.is_reimbursement && snapshot.entry_status == 1 && snapshot.old_entry_status == 2 ) {
            widget_count.for_approval = (parseInt(widget_count.for_approval) - 1);
          } 
        }

        this.props.dispatch(setSnapshotWidgets(widget_count));
      }
    } else {
      this.setState({
        snapshot_list: [snapshot, ...this.state.snapshot_list]
      })

      let widget_count = this.props.widget_count;

      widget_count.total = (parseInt(widget_count.total) + 1);

      if(snapshot.entry_status == 1) {
        widget_count.draft = (parseInt(widget_count.draft) + 1);
      } else if(snapshot.entry_status == 2) {
        widget_count.ready = (parseInt(widget_count.ready) + 1);
      }

      if(snapshot.accounting_status == 3) {
        widget_count.pending = (parseInt(widget_count.pending) + 1);
      } else if(snapshot.accounting_status == 4) {
        widget_count.accounted = (parseInt(widget_count.accounted) + 1);
      } else if (snapshot.accounting_status == 5) {
        widget_count.synced_by_user = (parseInt(widget_count.synced_by_user) + 1);
      }

      if(snapshot.is_reimbursement && isNaN(snapshot.approval_status) && snapshot.entry_status == 2) {
        widget_count.for_approval = (parseInt(widget_count.for_approval) + 1);
      } else if(snapshot.is_reimbursement && snapshot.approval_status == 1) {
        widget_count.approved = (parseInt(widget_count.approved) + 1);
      }
      //console.log(widget_count)
      this.props.dispatch(setSnapshotWidgets(widget_count));
    }

    if(!!snapshot.new_tags) {
      if (snapshot.new_tags.length > 0) {
        this.setState({
          listed_tags: _.merge( this.state.listed_tags, snapshot.new_tags )
        })
      }
    }
    //console.log(snapshot.categories)
    if (snapshot.categories.length > 0) {

      var new_categories = snapshot.categories.filter((obj) => { return this.state.categories.indexOf(obj) == -1; });
     // console.log(new_categories)
      if(new_categories.length > 0) {
        this.setState({
          categories: new_categories
        })
      }
    }

    //this.getSnapshotList();
   
    if (save_and_next) { 
      let next_trasaction = this.state.snapshot_list[index + 1];
      if (next_trasaction) { 
          this.props.history.push('/snapshot/evidence/create/' + next_trasaction.id)
      } else if (this.state.has_next) {
        this.setState({ page_no: this.state.page_no + 1 }, () => {
          this.props.dispatch(setPageNo(this.state.page_no));
          this.setState({save_and_nexts: true}, () => {
            this.getSnapshotList();
          })
        })
      } else {
        this.props.history.push('/snapshot/evidence/create/' + snapshot.id)
      }
    }

    if (!save_and_next && is_update == 0) { 
    	this.props.history.push('/snapshot/evidence/create/' + snapshot.id)
    }
  }

  // ------ Added by Ram ----------
  changeSnapshotList = (snapshotList) => {
    this.setState({ snapshot_list: [...snapshotList]})
  }

  changeIsSearchApplied = (isSearch) => {
    this.setState({ isSearchApplied: isSearch })
  }
  //-------------------------------

  setSMSPageNo = (page_no) => {
    this.setState({
      sms_page_no: page_no
    }, () => this.getSmsList())
  }

  applySMSFilter = (type, filter_data) => {
    this.setState({
      sms_page_no: 1,
      sms_from_amount: filter_data.from_amount,
      sms_to_amount: filter_data.to_amount,
      sms_financial_institute_id: filter_data.financial_institute_id,
      classification: filter_data.classification,
    }, () => {
      type == "apply" ? this.getSmsList(2) : this.getSmsList(0);
    })
  }

  finishBulkUpload = (snapshot) => {
    this.setState({
      snapshot_list: [...snapshot, ...this.state.snapshot_list]
    })
    this.getSnapshotList();
  }

  finishImportExcelUpload = (snapshot) => {
    this.setState({
      snapshot_list: [...snapshot, ...this.state.snapshot_list]
    })
    this.getSnapshotList();
  }

  dateTypeChanged = (e) => {
    this.setState({date_type: parseInt(e.target.value)}, () => {
      // if(this.state.start_date && this.state.end_date){
      //   this.getSnapshotList();
      // }    
      StorageData.setDateType(this.state.date_type)
      if(this.props.filter_start_date_range && this.props.filter_end_date_range){
        
        this.getSnapshotList()
      }
    })
  }

  resetSaveAndNext = () => {
    this.setState({save_and_nexts: false})
}
//new snapshot delete select
resetSelection = () => {
  this.bulklist.resetSelection();
}


checkBulkFlag = async()=>{
  if (this.props.bulk_snapshot_list.length) {

    //const result = await confirm("This action will clear the selection. Do you want to continue?", options);
    if (await confirm("This action will clear the selection. Do you want to continue?", options
    )) {
        //resetSelection
        this.bulklist.resetSelection();
        return false;
    } else {
        return true;
    }
} else {
    return false;
}

}
checkQuickFlag = async()=>{
  if (this.props.bulk_snapshot_list.length) {

    //const result = await confirm("This action will clear the selection. Do you want to continue?", options);
    if (await confirm("This action will clear the selection. Do you want to continue?", options
    )) {
        //resetSelection
        this.snapshotQuickEntry.resetSelection();
        return false;
    } else {
        return true;
    }
} else {
    return false;
}

}


//selecting all entries for bulk snapshots 

selectAllSnapshots = () =>{
  this.props.dispatch(setBulkSnapshotList([]));
  this.props.dispatch(setSnapshotLoadingStatus(true));
  this.props.dispatch(setAppilcationLoder(true));

  let filter_data = {
    "per_page_items": 0,
    "page_no": 0,
    "date_filter": {
      "start_date": changeDateFormate(this.state.start_date),
      "end_date": changeDateFormate(this.state.end_date),
      "date_type": this.state.date_type
    },
    "filter_card": this.state.filter_card,
    "filter": this.state.filter_by,
    "search": this.props.search_text,
    "filter_box": {
      "type": !!this.props.snapshot_type ? parseInt(this.props.snapshot_type) : null,
      "snapshot_number": !!this.props.snapshot_number ? this.props.snapshot_number : null,
      "supplier_name": !!this.props.supplier_name ? this.props.supplier_name : null,
      "from_amount": !!this.props.from_amount ? this.props.from_amount : null,
      "to_amount": !!this.props.to_amount ? this.props.to_amount : null,
      "payment_status": !!this.props.payment_status ? parseInt(this.props.payment_status) : null,
      "created_by": !!this.props.created_by ? this.props.created_by : null,
      "is_star": this.props.is_star !== '' ? parseInt(this.props.is_star) : null,
      "voucher_status": this.props.voucher_status !== '' ? parseInt(this.props.voucher_status) : null,
      "tags": !!this.props.tags ? [this.props.tags] : [],
      "category": !!this.props.category_id ? this.props.category_id : null,
      "payment_method": null,
      "source": this.props.source !== '' ? parseInt(this.props.source) : null,
      "attachment_status" : this.props.attachment_status !== '' ? parseInt(this.props.attachment_status) : null,
      "gst_status" : this.props.gst_status !== '' ? parseInt(this.props.gst_status) : null,
      "messages": this.props.messages !== '' ? parseInt(this.props.messages) : null, 
      "tds_status": this.props.tds_status !== '' ? parseInt(this.props.tds_status) : null,
      "is_entity_mismatch": this.props.is_entity_mismatch != null ? parseInt(this.props.is_entity_mismatch) : null
    },
    "is_export":0,
    "sort_by": this.state.sort_by,
    "sort_order": this.state.sort_order
  };

  SnapshotService.getSnapshotList(filter_data).then((data) => {
    this.props.dispatch(setSnapshotLoadingStatus(false));
    this.props.dispatch(setAppilcationLoder(false));

    if(data.response_code == 200){

      console.log(":::::::select all :::::::::",data.result.snapshots);
      let new_list = data.result.snapshots;

      let selected_items = new_list.map(data => data.id);
      this.bulklist.selectedSnapshots(selected_items);
      this.props.dispatch(setBulkSnapshotList(new_list));

    }else {
                toast.error(data.message);
            }

  })


}

selectAllQuickSnapshot = async() =>{
  this.props.dispatch(setBulkSnapshotList([]));
  this.props.dispatch(setSnapshotLoadingStatus(true));
  this.props.dispatch(setAppilcationLoder(true));


  let filter_data = {
    "per_page_items": 0,
    "page_no": 0,
    "date_filter": {
      "start_date": changeDateFormate(this.state.start_date),
      "end_date": changeDateFormate(this.state.end_date),
      "date_type": this.state.date_type
    },
    "filter_card": this.state.filter_card,
    "filter": this.state.filter_by,
    "search": this.props.search_text,
    "filter_box": {
      "type": !!this.props.snapshot_type ? parseInt(this.props.snapshot_type) : null,
      "snapshot_number": !!this.props.snapshot_number ? this.props.snapshot_number : null,
      "supplier_name": !!this.props.supplier_name ? this.props.supplier_name : null,
      "from_amount": !!this.props.from_amount ? this.props.from_amount : null,
      "to_amount": !!this.props.to_amount ? this.props.to_amount : null,
      "payment_status": !!this.props.payment_status ? parseInt(this.props.payment_status) : null,
      "created_by": !!this.props.created_by ? this.props.created_by : null,
      "is_star": this.props.is_star !== '' ? parseInt(this.props.is_star) : null,
      "voucher_status": this.props.voucher_status !== '' ? parseInt(this.props.voucher_status) : null,
      "tags": !!this.props.tags ? [this.props.tags] : [],
      "category": !!this.props.category_id ? this.props.category_id : null,
      "payment_method": null,
      "source": this.props.source !== '' ? parseInt(this.props.source) : null,
      "attachment_status" : this.props.attachment_status !== '' ? parseInt(this.props.attachment_status) : null,
      "gst_status" : this.props.gst_status !== '' ? parseInt(this.props.gst_status) : null,
      "messages": this.props.messages !== '' ? parseInt(this.props.messages) : null, 
      "tds_status": this.props.tds_status !== '' ? parseInt(this.props.tds_status) : null,
      "is_entity_mismatch": this.props.is_entity_mismatch != null ? parseInt(this.props.is_entity_mismatch) : null
    },
    "is_export":0,
    "sort_by": this.state.sort_by,
    "sort_order": this.state.sort_order,
    "quick_entry":this.state.quick_entry
  };

  SnapshotService.getSnapshotList(filter_data).then((data) => {
    this.props.dispatch(setSnapshotLoadingStatus(false));
    this.props.dispatch(setAppilcationLoder(false));

    if(data.response_code == 200){

      console.log(":::::::select all :::::::::",data.result.snapshots);
      let new_list = data.result.snapshots;

      let selected_items = new_list.map(data => data.id);
      this.snapshotQuickEntry.selectedSnapshots(selected_items);
      this.props.dispatch(setBulkSnapshotList(new_list));

    }else {
                toast.error(data.message);
            }

  })
}

  render() {
    console.log("filter date",this.props.filter_start_date_range)
    console.log("filter date",this.props.filter_end_date_range)
    //console.log(this.props.widget_count)
    return (
      <div>
        <ScrollPage />
        <Switch>
          <Redirect from='/su/:snapshot_id?' to="/snapshot/evidence/create/:snapshot_id?"/> 
          <Route path={["/snapshot/:evidence_type?/create/:snapshot_id?", "/su/:snapshot_id?"]}
          
            render={() => {
              return <CreateSnapshotView
                snapshot_list={this.state.snapshot_list}
                changeSnapshotList={this.changeSnapshotList}   // Added by Ram
                changeIsSearchApplied={this.changeIsSearchApplied}   // Added by Ram
                isSearchApplied={this.state.isSearchApplied}
                applyFilter={this.applyFilter}
                creaters={this.state.creaters}
                filter_by={this.state.filter_by}
                filter_card={this.state.filter_card}
                searchFilter={this.searchFilter}           // --------> Ram
                removeSnapshot={this.removeSnapshot}
                newSnapshot={this.newSnapshot}
                has_next={this.state.has_next}
                has_previous={this.state.has_previous}
                per_page_items={this.state.per_page_items}
                page_no={this.state.page_no}
                handlePageChange={this.handlePageChange}
                listed_tags={this.state.listed_tags}
                financial_institutes={this.state.financial_institutes}
                categories={this.state.categories}
                snapshot_types={this.state.snapshot_types}
                resetSaveAndNext={this.resetSaveAndNext}
                total_pages={this.state.total_pages}
              />
            }
            }
            exact />
          <Route path={["/snapshot/evidence", "/snapshot"]}
            render={() => {
              return <SnapshotList
                filter_by={this.state.filter_by}
                snapshot_list={this.state.snapshot_list}
                changeIsSearchApplied={this.changeIsSearchApplied}   // Added by Ram
                isSearchApplied={this.state.isSearchApplied}         // Added by Ram
                applyFilter={this.applyFilter}
                creaters={this.state.creaters}
                total_count={this.state.total_count}
                financialYearChanged={this.financialYearChanged}
                onCardSelected={this.onCardSelected}
                searchFilter={this.searchFilter}
                makeRemoveStar={this.makeRemoveStar}
                refreshItem={this.refreshItem}
                page_no={this.state.page_no}
                listed_tags={this.state.listed_tags}
                financial_institutes={this.state.financial_institutes}
                categories={this.state.categories}
                per_page_items={this.state.per_page_items}
                handlePageChange={this.handlePageChange}
                is_search={this.state.is_search}
                snapshot_types={this.state.snapshot_types}
                sort_by={this.state.sort_by}
                sort_order={this.state.sort_order}
                onSortClick={this.onSortClick}
                filter_card={this.state.filter_card}
                handleTabChange={this.handleTabChange} 
                finishBulkUpload={this.finishBulkUpload}
                finishImportExcelUpload={this.finishImportExcelUpload}
                getSnapshotList={this.getSnapshotList} 
                default_cards={this.state.default_cards}
                dateTypeChanged={this.dateTypeChanged}
                date_type={this.state.date_type}
                checkBulkFlag = {this.checkQuickFlag}
                handleQuickEntryTabChange = {this.handleQuickEntryTabChange}
              />
            }
            }
            exact />

            <Route path={["/snapshot/quick-entry", "/snapshot"]}
            render={() => {
              return <SnapshotQuickEntry
                onRef={ref => (this.snapshotQuickEntry = ref)}
                filter_by={this.state.filter_by}
                snapshot_list={this.state.snapshot_list}
                changeIsSearchApplied={this.changeIsSearchApplied}   // Added by Ram
                isSearchApplied={this.state.isSearchApplied}         // Added by Ram
                applyFilter={this.quickEntryApplyFilter}
                creaters={this.state.creaters}
                total_count={this.state.total_count}
                financialYearChanged={this.financialYearChanged}
                onCardSelected={this.onCardSelected}
                searchFilter={this.quickEntrySearchFilter}
                makeRemoveStar={this.makeRemoveStar}
                refreshItem={this.quickEntryRefreshItem}
                page_no={this.state.page_no}
                listed_tags={this.state.listed_tags}
                financial_institutes={this.state.financial_institutes}
                categories={this.state.categories}
                per_page_items={this.state.per_page_items}
                handlePageChange={this.handlePageChange}
                is_search={this.state.is_search}
                snapshot_types={this.state.snapshot_types}
                sort_by={this.state.sort_by}
                sort_order={this.state.sort_order}
                onSortClick={this.quicEntryOnSortClick}
                filter_card={this.state.filter_card}
                handleTabChange={this.handleTabChange} 
                finishBulkUpload={this.finishBulkUpload}
                getSnapshotList={this.getSnapshotList} 
                default_cards={this.state.default_cards}
                dateTypeChanged={this.dateTypeChanged}
                date_type={this.state.date_type}
                resetSelection = {this.resetSelection}
                selectAllSnapshots = {this.selectAllQuickSnapshot}
                checkBulkFlag = {this.checkQuickFlag}
                handleQuickEntryTabChange = {this.handleQuickEntryTabChange}
              />
            }
            }
            exact />

<Route path={["/snapshot/quick-entry-create"]}
            render={() => {
              return <SnapshotQuickView
              onRef={ref => (this.SnapshotQuickView = ref)}
              handleQuickEntryTabChange = {this.handleTabQuickView}
              refreshItem = {this.refreshItemCreate}
              />
            }
            }
            exact />

                <Route path={["/snapshot/bulk-action", "/snapshot"]}
            render={() => {
              return <BulkAction
              onRef={ref => (this.bulklist = ref)}
                filter_by={this.state.filter_by}
                snapshot_list={this.state.snapshot_list}
                changeIsSearchApplied={this.changeIsSearchApplied}   // Added by Ram
                isSearchApplied={this.state.isSearchApplied}         // Added by Ram
                applyFilter={this.applyFilter}
                creaters={this.state.creaters}
                total_count={this.state.total_count}
                financialYearChanged={this.financialYearChanged}
                onCardSelected={this.onCardSelected}
                searchFilter={this.searchFilter}
                makeRemoveStar={this.makeRemoveStar}
                refreshItem={this.refreshItem}
                page_no={this.state.page_no}
                listed_tags={this.state.listed_tags}
                financial_institutes={this.state.financial_institutes}
                categories={this.state.categories}
                per_page_items={this.state.per_page_items}
                handlePageChange={this.handlePageChange}
                is_search={this.state.is_search}
                snapshot_types={this.state.snapshot_types}
                sort_by={this.state.sort_by}
                sort_order={this.state.sort_order}
                onSortClick={this.onSortClick}
                filter_card={this.state.filter_card}
                handleTabChange={this.handleTabChange} 
                finishBulkUpload={this.finishBulkUpload}
                getSnapshotList={this.getSnapshotList} 
                default_cards={this.state.default_cards}
                dateTypeChanged={this.dateTypeChanged}
                date_type={this.state.date_type}
                resetSelection = {this.resetSelection}
                selectAllSnapshots = {this.selectAllSnapshots}
                checkBulkFlag = {this.checkBulkFlag}
                handleQuickEntryTabChange = {this.handleQuickEntryTabChange}

              />
            }
            }
            exact />
            { this.ACCESS_BUSSINESS_SMS_SNAPSHOT &&
              <Route path="/snapshot/sms"
                render={() => {
                  return <SMSView
                    sms_list={this.state.sms_list}
                    sms_total_count={this.state.sms_total_count}
                    financial_institute_list={this.state.financial_institute_list}
                    sort_by={this.state.sms_sort_by}
                    sort_order={this.state.sms_sort_order}
                    getSmsList={this.getSmsList}
                    setSMSPageNo={this.setSMSPageNo}
                    handleSearch={this.handleSearch}
                    applySMSFilter={this.applySMSFilter}
                    onSmsSortClick={this.onSmsSortClick}
                    smsFinancialYearChanged={this.financialYearChanged}
                    smsRefreshItem={this.smsRefreshItem}
                    handleClassification={this.handleClassification}
                    handleTabChange={this.handleTabChange} />
                }
                }
                exact />
            }
            <Redirect to="/welcome" />
        </Switch>
      </div>
    );
  }

}

function mapStateToProps(state) {
  const all_value = state.Snapshot || {};
  const snapshot_type = all_value.snapshot_type || '';
  const snapshot_number = all_value.snapshot_number || '';
  const supplier_name = all_value.supplier_name || '';
  const from_amount = all_value.from_amount || '';
  const to_amount = all_value.to_amount || '';
  const payment_status = all_value.payment_status || '';
  const created_by = all_value.created_by || '';
  const is_star = all_value.is_star || '';
  const voucher_status = all_value.voucher_status || '';
  const source = all_value.source || '';
  const changed_star = all_value.changed_star || 0;
  const snapshot_id = all_value.snapshot_id || null;
  const tags = all_value.tags || '';
  const category_id = all_value.category_id || '';
  const payment_method = all_value.payment_method || '0_0';
  const sms_per_page_items = all_value.sms_per_page_items || 0;
  const sms_page_no = all_value.sms_page_no || 1;
  const search_text = all_value.search_text || "";
  const filter_by = all_value.filter_by || 0;

  const session_values = state.Session || {};
  const features_permissions_list = session_values.features_permissions_list || [];
  const system_parameter = session_values.system_parameter || {};
  const per_page_items = system_parameter.per_page_items || 0;
  const bulk_snapshot_list = all_value.bulk_snapshot_list || [];
  const filter_start_date_range = all_value.filter_start_date || "";
  const filter_end_date_range = all_value.filter_end_date || "";


  const attachment_status = all_value.attachment_status || '';
  const is_entity_mismatch = all_value.is_entity_mismatch || '';
	const gst_status = all_value.gst_status || '';
	const messages = all_value.messages || '';
	const tds_status = all_value.tds_status || '';
  const entity_name = session_values.default_entity_detail.name || {};
  const widget_count = all_value.widget_count || {total: 0, draft: 0, ready: 0, pending: 0, accounted: 0, synced: 0, synced_by_user: 0, for_approval: 0, approved: 0, linked: 0, not_linked: 0};


  return {
   bulk_snapshot_list, is_star, snapshot_type, snapshot_number, supplier_name, from_amount, to_amount, category_id,
    payment_status, created_by, changed_star, snapshot_id, tags, payment_method, sms_per_page_items,
    sms_page_no,features_permissions_list,search_text,voucher_status,per_page_items,system_parameter, source,filter_by,
    attachment_status, gst_status, messages, tds_status, entity_name, is_entity_mismatch, widget_count,filter_start_date_range,filter_end_date_range

  };
}

export default connect(mapStateToProps)(SnapshotView);